import {QuestionSet} from "../../javascript.lib.mojo-base/model/QuestionSet";
import {Question} from "../../javascript.lib.mojo-base/model/Question";
import {FacilityEnumeratedConstants} from "./FacilityEnumeratedConstants";


export class FacilityQuestions4 {

  public static readonly EXTRA_FACILITIES_AND_EVENT_SPACES_QUESTIONS: QuestionSet = new QuestionSet( [

    {
      key: 'goVTa', // Is pre-visit information provided for employees and visitors?
      label: 'Is pre-visit information provided for employees and visitors? ',
      helpImage: null,
      helpImages: [],
      helpText: 'Pre-visit information (usually online) is important in allowing someone to plan and prepare for their visit, thus reducing the risk of anxiety.          Examples of this include: Links to journey information, such as travel and parking options. Information about the environment, reception area and what to expect during a visit. ',
      popupLabel: 'Pre-visit Information',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 1,
            onFalse: 0,
          }
        }
      },
      maximumScore: 1,
    },

    {
      key: 'goXCC', // Is a simple plan of the interior layout of the building available for employees?
      label: 'Is a simple plan of the interior layout of the building available for employees?',
      helpImage: null,
      helpImages: [],
      helpText: 'An interior plan at the entrance to a building can include: Colour coded maps with visual cues to facilities and locations; Using QR codes around the building to confirm your location; A wayfinding app providing indoor mapping and positioning with navigational instructions.',
      popupLabel: 'Info on Interior Layout of Building',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 1,
            onFalse: 0,
          }
        }
      },
      maximumScore: 1,
    },


    {
      key: 'goXEi', // Are plants and biophilic design principles widely incorporated throughout the indoor areas of your building?
      label: 'Are plants and biophilic design principles widely incorporated throughout the indoor areas of your building?',
      helpImage: null,
      helpImages: [],
      helpText: 'Biophilic design includes appropriate planting, potted plants, views to nature from the inside of the building, natural light, and direct access to nature like courtyards, gardens and roof terraces planted with greenery. These direct connections to nature have the strongest impact on us as humans.',
      popupLabel: 'Nature As Part of Work Environment',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 1,
            onFalse: 0,
          }
        }
      },
      maximumScore: 1,
    },


    {
      key: 'goXCg', // Do on-site catering services offer pre-ordering systems?
      label: 'Do on-site catering services offer pre-ordering systems?',
      helpImage: null,
      helpImages: [],
      helpText: 'Cafeterias can be very noisy during lunch time. Having the option to pre-order food allows those who are sensitive to noise and crowded environments to avoid these spaces during busy times.',
      popupLabel: 'Pre-ordering System Available',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 1,
            onFalse: 0,
          }
        }
      },
      maximumScore: 1,
      dependant: {
        questionKey: 'BkzJT' // Is there a cafeteria on the premises?
      }
    },

    {
      key: 'g5E62', // Do on-site catering services offer pre-payment systems?
      label: 'Do on-site catering services offer pre-payment systems?',
      helpImage: null,
      helpImages: [],
      helpText: 'Queues at checkout tills can be noisy and confusing for some people. Having the option to pre-pay for food allows those who are sensitive to noise and crowded environments to avoid these spaces during busy times.',
      popupLabel: 'Pre-payment System Available',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 1,
            onFalse: 0,
          }
        }
      },
      maximumScore: 1,
      dependant: {
        questionKey: 'BkzJT' // Is there a cafeteria on the premises?
      }
    },


    {
      key: 'g792U', // Is there sufficient lighting in the auditorium to support lip reading and sign language interpreting?
      label: 'Is there sufficient lighting in the auditorium to support lip reading and sign language interpreting?',
      helpImage: null,
      helpImages: [],
      helpText: 'Is the auditorium sufficiently well-lit to allow spectators to lip read and follow sign language interpreting where available? ',
      popupLabel: '',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 2,
            onFalse: 0,
          }
        }
      },
      maximumScore: 2,
      dependant: {
        questionKey: 'Bkzpp', // Is there an auditorium in the building?
      }
    },

    {
      key: 'g79xU', // Is there an elevated stage/speaker area in the auditorium?
      label: 'Is there an elevated stage/speaker area in the auditorium?',
      helpImage: null,
      helpImages: [],
      helpText: 'In the auditorium, is the stage where speakers perform raised from the main ground level? ',
      popupLabel: '',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 0,
            onFalse: 0,
          }
        }
      },
      maximumScore: 0,
      dependant: {
        questionKey: 'Bkzpp', // Is there an auditorium in the building?
      }
    },

    {
      key: 'g79yu', // Are there steps or ramps up to the stage/presenter area?
      label: 'Are there steps or ramps up to the stage/presenter area?',
      helpImage: null,
      helpImages: [],
      helpText: 'Ramped access should be provided to form a continuous accessible path of travel to the stage or platform. Ideally, both steps and ramps should be provided as access options to an auditorium\'s stage.  ',
      popupLabel: '',
      type: Question.TYPE_ENUM,
      type2: {
        typeEnum: {
          options: [
            FacilityEnumeratedConstants.STAGE_ACCESS.steps,
            FacilityEnumeratedConstants.STAGE_ACCESS.ramp,
            FacilityEnumeratedConstants.STAGE_ACCESS.both,
            FacilityEnumeratedConstants.STAGE_ACCESS.none,
          ],
          scoring: [
            {
              codeAsNumber: FacilityEnumeratedConstants.STAGE_ACCESS.steps.codeAsNumber,
              score: 0,
            },
            {
              codeAsNumber: FacilityEnumeratedConstants.STAGE_ACCESS.ramp.codeAsNumber,
              score: 1,
            },
            {
              codeAsNumber: FacilityEnumeratedConstants.STAGE_ACCESS.both.codeAsNumber,
              score: 2,
            },
            {
              codeAsNumber: FacilityEnumeratedConstants.STAGE_ACCESS.none.codeAsNumber,
              score: 0,
            },
          ],
        }
      },
      maximumScore: 2,
      dependant: {
        questionKey: 'g79xU', // Is there an elevated stage/speaker area in the auditorium?
      }
    },


    {
      key: 'g79zq', // Are handrails provided at steps?
      label: 'Are handrails provided at steps?',
      helpImage: null,
      helpImages: [],
      helpText: 'Handrails should be provided at steps to help prevent falls and injuries.',
      popupLabel: '',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 1,
            onFalse: 0,
          }
        }
      },
      maximumScore: 1,
      dependant: {
        questionKey: 'g79yu', // Are there steps or ramps up to the stage/presenter area?
        truthyEnumCodes: [
          FacilityEnumeratedConstants.STAGE_ACCESS.steps.codeAsNumber,
          FacilityEnumeratedConstants.STAGE_ACCESS.both.codeAsNumber,
        ]
      }
    },

    {
      key: 'g7906', // Does the ramp have a gentle angle?
      label: 'Does the ramp have a gentle angle?',
      helpImage: null,
      helpImages: [],
      helpText: ' It is recommended that, for every inch of height change, there should be at least 12 inches of ramp run. Ramps that are steeper than that are too steep for wheelchair users to use independently. ',
      popupLabel: '',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 1,
            onFalse: 0,
          }
        }
      },
      maximumScore: 1,
      dependant: {
        questionKey: 'g79yu', // Are there steps or ramps up to the stage/presenter area?
        truthyEnumCodes: [
          FacilityEnumeratedConstants.STAGE_ACCESS.ramp.codeAsNumber,
          FacilityEnumeratedConstants.STAGE_ACCESS.both.codeAsNumber,
        ]
      }
    },

    {
      key: 'g793k', // Please take 2 photos of the ramp from different angles (in landscape mode)
      label: 'Please take 2 photos of the ramp from different angles (in landscape mode)',
      helpImage: null,
      helpImages: [],
      helpText: ' It is recommended that, for every inch of height change, there should be at least 12 inches of ramp run. Ramps that are steeper than that are too steep for wheelchair users to use independently. ',
      popupLabel: '',
      type: Question.TYPE_PHOTO,
      type2: {
        typePhoto: {
        },
      },
      maximumScore: 0,
      dependant: {
        questionKey: 'g79yu', // Are there steps or ramps up to the stage/presenter area?
        truthyEnumCodes: [
          FacilityEnumeratedConstants.STAGE_ACCESS.ramp.codeAsNumber,
          FacilityEnumeratedConstants.STAGE_ACCESS.both.codeAsNumber,
        ]
      }
    },

    {
      key: 'g8QA-', // Is there a stand (lectern) or table available for presenters?
      label: 'Is there a stand (lectern) or table available for presenters? ',
      helpImage: null,
      helpImages: [],
      helpText: 'Does the auditorium have a stand (lectern) or table to support the presenter\'s microphone and notes? ',
      popupLabel: '',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 1,
            onFalse: 0,
          }
        }
      },
      maximumScore: 1,
      dependant: {
        questionKey: 'Bkzpp', // Is there an auditorium in the building?
      }
    },

    {
      key: 'g8QVI', // Is the stand (lectern) or table accessible for wheelchair users and persons of shorter stature?
      label: 'Is the stand (lectern) or table accessible for wheelchair users and persons of shorter stature?',
      helpImage: null,
      helpImages: [],
      helpText: 'Is the stand (lectern) or table accessible to presenters of different heights and who might be presenting from seated position?',
      popupLabel: '',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 1,
            onFalse: 0,
          }
        }
      },
      maximumScore: 1,
      dependant: {
        questionKey: 'g8QA-', // Is there a stand (lectern) or table available for presenters?
      }
    },


  ] );
  public static readonly EXTRA_PARKING_AND_ENTRANCE_QUESTIONS: QuestionSet = new QuestionSet( [

    {
      key: 'goXFK', // Is your building part of a multi-building campus?
      label: 'Is your building part of a multi-building campus? ',
      helpImage: null,
      helpImages: [],
      helpText: '',
      popupLabel: '',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 0,
            onFalse: 0,
          }
        }
      },
      maximumScore: 0,
    },


    {
      key: 'goXFo', // Are direction information signs or large orientation maps provided to help users navigate routes and locate key buildings?
      label: 'Are direction information signs or large orientation maps provided to help users navigate routes and locate key buildings?',
      helpImage: null,
      helpImages: [],
      helpText: 'Are informative, directional and locational signs positioned at frequent intervals and consistent locations along the accessible route within the site/campus grounds? This is to aid wayfinding for users.',
      popupLabel: '',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 1,
            onFalse: 0,
          }
        }
      },
      maximumScore: 1,
      dependant: {
        questionKey: 'goXFK', // Is your building part of a multi-building campus?
      },
    },


    {
      key: 'goXGG', // Are main access routes that connect to this building free from steps and obstructions?
      label: 'Are main access routes that connect to this building free from steps and obstructions?',
      helpImage: null,
      helpImages: [],
      helpText: 'Do pedestrian routes linking buildings, crossings, parking and transport, have any steps and obstructions such as bollards?',
      popupLabel: '',
      type: Question.TYPE_ENUM,
      type2: {
        typeEnum: {
          options: [
            FacilityEnumeratedConstants.STEP_FREE_BUILDING_ACCESS_ROUTES.all,
            FacilityEnumeratedConstants.STEP_FREE_BUILDING_ACCESS_ROUTES.some,
            FacilityEnumeratedConstants.STEP_FREE_BUILDING_ACCESS_ROUTES.none,
          ],
          scoring: [
            {
              codeAsNumber: FacilityEnumeratedConstants.STEP_FREE_BUILDING_ACCESS_ROUTES.all.codeAsNumber,
              score: 3,
            },
            {
              codeAsNumber: FacilityEnumeratedConstants.STEP_FREE_BUILDING_ACCESS_ROUTES.some.codeAsNumber,
              score: 0,
            },
            {
              codeAsNumber: FacilityEnumeratedConstants.STEP_FREE_BUILDING_ACCESS_ROUTES.none.codeAsNumber,
              score: 0,
            },
          ],
        }
      },
      maximumScore: 3,
      dependant: {
        questionKey: 'goXFK', // Is your building part of a multi-building campus?
      },

    },

    {
      key: 'goXGa', // Are handrails provided where there are steps?
      label: 'Are handrails provided where there are steps?',
      helpImage: null,
      helpImages: [],
      helpText: 'Are handrails provided where there are steps or ramps?',
      popupLabel: '',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 1,
            onFalse: 0,
          }
        }
      },
      maximumScore: 1,
      dependant: {
        questionKey: 'goXGG', // Are main access routes that connect to this building free from steps and obstructions?
        truthyEnumCodes: [
          FacilityEnumeratedConstants.STEP_FREE_BUILDING_ACCESS_ROUTES.some.codeAsNumber,
          FacilityEnumeratedConstants.STEP_FREE_BUILDING_ACCESS_ROUTES.none.codeAsNumber,
        ]
      },
    },


    {
      key: 'goXHC', // Do handrails color contrast visually with the background to which they are fixed?
      label: 'Do handrails color contrast visually with the background to which they are fixed?',
      helpImage: null,
      helpImages: [],
      helpText: 'Handrails that are a different colour and visually contrast with the surrounding wall can assist people with visual impairment. Handrails should be clearly visible and not blend in with their surrounding environment.',
      popupLabel: '',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 1,
            onFalse: 0,
          }
        }
      },
      maximumScore: 1,
      dependant: {
        questionKey: 'goXGa' // Are handrails provided where there are steps?
      },
    },

    {
      key: 'goXHg', // Are the first and last steps clearly marked with a different color or tactile floor warning indicators?
      label: 'Are the first and last steps clearly marked with a different color or tactile floor warning indicators?',
      helpImage: null,
      helpImages: [],
      helpText: 'Warning Tactile Indicators installed on the walking surface in a raised grid pattern of studs or \'dots\'  warn blind and vision-impaired pedestrians of nearby steps.',
      popupLabel: '',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 3,
            onFalse: 0,
          }
        }
      },
      maximumScore: 3,
      dependant: {
        questionKey: 'goXGG', // Are main access routes that connect to this building free from steps and obstructions?
        truthyEnumCodes: [
          FacilityEnumeratedConstants.STEP_FREE_BUILDING_ACCESS_ROUTES.some.codeAsNumber,
          FacilityEnumeratedConstants.STEP_FREE_BUILDING_ACCESS_ROUTES.none.codeAsNumber,
        ]

      },
    },


    {
      key: 'goXII', // Are ramps provided in the main access routes to this building?
      label: 'Are ramps provided in the main access routes to this building?',
      helpImage: null,
      helpImages: [],
      helpText: 'The length, the rise and the slope of a ramp are interrelated. Where achievable it is recommended that ramps are made at as gentle an angle/slope as possible. No steeper than 1 in 12. That means for every inch of height change, there should be at least 12 inches of ramp run.',
      popupLabel: '',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 0,
            onFalse: 0,
          }
        }
      },
      maximumScore: 0,
      dependant: {
        questionKey: 'goXGG', // Are main access routes that connect to this building free from steps and obstructions?
        truthyEnumCodes: [
          FacilityEnumeratedConstants.STEP_FREE_BUILDING_ACCESS_ROUTES.all.codeAsNumber,
          FacilityEnumeratedConstants.STEP_FREE_BUILDING_ACCESS_ROUTES.some.codeAsNumber,
        ]
      },
    },

    {
      key: 'g5GgQ', // Are handrails provided on ramps?
      label: 'Are handrails provided on ramps?',
      helpImage: null,
      helpImages: [],
      helpText: 'Handrails should be provided at all ramps to help prevent falls and injuries.',
      popupLabel: '',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 1,
            onFalse: 0,
          }
        }
      },
      maximumScore: 1,
      dependant: {
        questionKey: 'goXII', // Are ramps provided in the main access routes to this building?
      },
    },
    {
      key: 'g5G4A',
      label: 'Do handrails colour contrast visually with the background to which they are fixed?',
      helpImage: null,
      helpImages: [],
      helpText: 'Handrails that are a different colour and visually contrast with the surrounding wall can assist people with visual impairment. Handrails should be clearly visible and not blend in with their surrounding environment.',
      popupLabel: '',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 2,
            onFalse: 0,
          }
        }
      },
      maximumScore: 2,
      dependant: {
        questionKey: 'g5GgQ', // Are handrails provided on ramps?
      },
    },
    {
      key: 'g7pf-', // Do ramps have a gentle angle/gradual slope?
      label: 'Do ramps have a gentle angle/gradual slope?',
      helpImage: null,
      helpImages: [],
      helpText: 'The length, the rise and the slope of a ramp are interrelated. Where possible, it is recommended that ramps are made at as gentle an angle/slope as possible. No steeper than 1 in 12. That means for every inch of height change, there should be at least 12 inches of ramp run.',
      popupLabel: '',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 1,
            onFalse: 0,
          }
        }
      },
      maximumScore: 1,
      dependant: {
        questionKey: 'goXII', // Are ramps provided in the main access routes to this building?
      },
    },

    {
      key: 'goXIw', // Please take a photo of a ramp provided in the main access routes to the building.
      label: 'Please take a photo of a ramp provided in the main access routes to the building.',
      helpImage: null,
      helpImages: [],
      helpText: 'Take 1 good quality photo of a typical external main route.',
      popupLabel: '',
      type: Question.TYPE_PHOTO,
      maximumScore: 0,
      dependant: {
        questionKey: 'goXII' // Are ramps provided in the main access routes to this building?
      },
    },


    {
      key: 'gou1o', // Are the ground surfaces of outdoor pedestrian pathways stable, firm, and slip-resistant?
      label: 'Are the ground surfaces of outdoor pedestrian pathways stable, firm, and slip-resistant?',
      helpImage: null,
      helpImages: [],
      helpText: 'Are the connecting path surfaces made with materials that reduce slipping and tripping and are easy to traverse.',
      popupLabel: '',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 2,
            onFalse: 0,
          }
        }
      },
      maximumScore: 2,
      dependant: {
        questionKey: 'goXFK', // Is your building part of a multi-building campus?
      },
    },

    {
      key: 'gou2Q', // Do all pedestrian crossing points have dropped curbs?
      label: 'Do all pedestrian crossing points have dropped curbs?',
      helpImage: null,
      helpImages: [],
      helpText: 'Curb ramps (sometimes called dropped or dished curbs) at pedestrian crossings are where the pavement slopes down to connect to the road, making it easier for wheeled devices to cross without the need to step up or down.',
      popupLabel: '',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 2,
            onFalse: 0,
          }
        }
      },
      maximumScore: 2,
      dependant: {
        questionKey: 'goXFK', // Is your building part of a multi-building campus?
      },
    },

    {
      key: 'gou24', // Please take a photo of a dropped curb in the main access routes to the building.
      label: 'Please take a photo of a dropped curb in the main access routes to the building.',
      helpImage: null,
      helpImages: [],
      helpText: 'Take 1 good quality photo of a typical dropped curb.',
      popupLabel: '',
      type: Question.TYPE_PHOTO,
      maximumScore: 0,
      dependant: {
        questionKey: 'gou2Q' // Do all pedestrian crossing points have dropped curbs?
      },
    },

    {
      key: 'gou30', // Are pedestrian crossing points marked with contrasting colour or tactile floor warning indicators?
      label: 'Are pedestrian crossing points marked with contrasting colour or tactile floor warning indicators? ',
      helpImage: null,
      helpImages: [],
      helpText: 'Do curb-less intersections have tactile indicators marked by raised dots with contrasting color and texture, to be detectable by white cane users?',
      popupLabel: '',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 1,
            onFalse: 0,
          }
        }
      },
      maximumScore: 1,
      dependant: {
        questionKey: 'goXFK', // Is your building part of a multi-building campus?
      },
    },

    {
      key: 'gou4m', // Are lights provided along exterior circulation routes for continuous illumination and wayfinding at night?
      label: 'Are lights provided along exterior circulation routes for continuous illumination and wayfinding at night?',
      helpImage: null,
      helpImages: [],
      helpText: 'Are circulation routes adequately lit for night time navigation and safety with even lighting to avoid contrasting pools of light and darkness?',
      popupLabel: '',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 2,
            onFalse: 0,
          }
        }
      },
      maximumScore: 2,
      dependant: {
        questionKey: 'goXFK', // Is your building part of a multi-building campus?
      },

    },
    {
      key: 'gou5E', // Are outdoor quiet spaces/resting areas provided for employees to use during breaks?
      label: 'Are outdoor quiet spaces/resting areas provided for employees to use during breaks?',
      helpImage: null,
      helpImages: [],
      helpText: 'Are quiet spaces provided in calm environments outside for everybody, and for providing respite for autistic employees to reduce over-stimulation?',
      popupLabel: '',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 2,
            onFalse: 0,
          }
        }
      },
      maximumScore: 2,
      dependant: {
        questionKey: 'goXFK', // Is your building part of a multi-building campus?
      },
    },

  ] );
  public static readonly EXTRA_WORK_STATIONS_AND_MEETING_ROOMS_QUESTIONS: QuestionSet = new QuestionSet( [

    {
      key: 'goVOk', // Can employees opt to work from a permanent desk space?
      label: 'Can employees opt to work from a permanent desk space?',
      helpImage: null,
      helpImages: [],
      helpText: 'Most people prefer a familiar place and position within a room, with many people choosing the same seat or desk every day. Employees should have a choice on where to sit as many factors such as noise, lighting, glare, density of smells, and décor can influence on their productivity.',
      popupLabel: 'Permanent Desk Spaces',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 1,
            onFalse: 0,
          }
        }
      },
      maximumScore: 1,
    },

    {
      key: 'goXC-', // Are noise cancelling headphones available for employees?
      label: 'Are noise cancelling headphones available for employees? ',
      helpImage: null,
      helpImages: [],
      helpText: 'Sensitivity to noise may vary from individual to individual. Some people might benefit  from using devices such as ear plugs or noise reducing headphones should be provided to block out any unwanted sound should be provided as an option. ',
      popupLabel: 'Noise-cancelling Earphones',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 2,
            onFalse: 0,
          }
        }
      },
      maximumScore: 2,
    },
    {
      key: 'goXDw', // Do employees have access to control panels that adjust lighting levels in the office workstations?
      label: 'Do employees have access to control panels that adjust lighting levels in the office workstations?',
      helpImage: null,
      helpImages: [],
      helpText: 'Good lighting is crucial in allowing people with sensory/neurological processing differences to use buildings conveniently and safely. Being able to adjust the lighting levels at their workstation can impact on comfort levels and glare.',
      popupLabel: 'Control Over Lighting Levels',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 1,
            onFalse: 0,
          }
        }
      },
      maximumScore: 1,
    },


    {
      key: 'goXEO', // Do employees have access to control panels that adjust temperature in the office workstations?
      label: 'Do employees have access to control panels that adjust temperature in the office workstations? ',
      helpImage: null,
      helpImages: [],
      helpText: 'Balancing temperature preferences and needs across a wide spectrum of people should be reviewed and assessed. The option, where practical, to have a choice of temperature and humidity settings in which to work is preferable and considerate to all.',
      popupLabel: 'Control Over Temperature Levels',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 1,
            onFalse: 0,
          }
        }
      },
      maximumScore: 1,
    },
    // {
    //   key: '', //
    //   label: '',
    //   helpImage: null,
    //   helpImages: [],
    //   helpText: '',
    //   popupLabel: '',
    //   type: QuestionReference.TYPE_BOOLEAN,
    //   type2: {
    //     typeBoolean: {
    //       scoring: {
    //         onTrue: 0,
    //         onFalse: 0,
    //       }
    //     }
    //   },
    //   maximumScore: 0,
    // },

  ] );

}
