import {ValueObject} from "../ValueObject";
import {IGuard} from "./Guard";
import {AnswerSet} from "../AnswerSet";
import {QuestionKey} from "../QuestionKey";
import {IValueCondition} from "./ValueCondition";


export class NotCondition extends ValueObject<IGuard> implements IGuard {


  isTrue(answers: AnswerSet): boolean {

    return !this.value.isTrue( answers );
  }

  public static not( value: IGuard ) {

    return new NotCondition( value );
  }


  protected onSetValue(value: IGuard | null) {
  }

  constructor( value: IGuard ) {

    super( value );
  }

}

