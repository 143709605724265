


export class BrowserAppEvaluationToolInfo {

  public version = '2023.10.19.2';

  constructor() {
  }

}
