import {ValueObject} from "../ValueObject";
import {AnswerSet} from "../AnswerSet";
import {IGuard} from "./Guard";
import {IEnumeratedConstant} from "../EnumeratedConstant";
import {QuestionKey} from "../QuestionKey";


export interface IValueCondition {

  targetQuestion: QuestionKey;

  booleanAnswer?: boolean;
  enumAnswer?: IEnumeratedConstant;
  integerGreaterThan?: number;
  integerLessThan?: number;
}


export class ValueCondition extends ValueObject<IValueCondition> implements IGuard {

  // targetEnum: number = null;
  integerGreaterThan: number = null;
  integerLessThan: number = null;

  public static booleanHasValue( targetQuestion: QuestionKey, booleanAnswer: boolean ) {

    const condition: IValueCondition = {
      targetQuestion,
      booleanAnswer
    }
    return new ValueCondition( condition );
  }

  public static enumHasValue( targetQuestion: QuestionKey, enumAnswer: IEnumeratedConstant ) {

    const condition: IValueCondition = {
      targetQuestion,
      enumAnswer
    }
    return new ValueCondition( condition );
  }


  // public static integerGreaterThan( targetQuestion: QuestionKey, value: number ) {
  //
  //
  //
  //
  //
  //
  // }

  protected onSetValue(value: IValueCondition | null) {

    // this.targetEnum = null;
    this.integerGreaterThan = null;
    this.integerLessThan = null;

    // if( value.enumAnswer ) {
    //
    //   this.targetEnum = EnumeratedConstantReference.asciiToNumberCode( this.value.enumAnswer );
    // }


    if( 'undefined' !== typeof value.integerGreaterThan ) {

      this.integerGreaterThan = value.integerGreaterThan;
    }

    if( 'undefined' !== typeof value.integerLessThan ) {

      this.integerLessThan = value.integerLessThan;
    }

  }

  isTrue( answers: AnswerSet ): boolean {

    const answer = answers.answerByKey[this.value.targetQuestion];
    if( !answer ) {

      return false;
    }

    if( answer.question.isBoolean ) {

      if( this.value.booleanAnswer === answer.value.value ) {

        return true;
      }

      return false;
    }

    if( answer.question.isEnum ) {

      if( this.value.enumAnswer.codeAsNumber === answer.value.value ) {

        return true;
      }

      return false;
    }

    if( answer.question.isInteger ) {

      if( null !== this.integerLessThan && null !== this.integerGreaterThan ) {

        if( this.integerLessThan >= answer.value.value ) {
          return false;
        }

        if( this.integerGreaterThan <= answer.value.value ) {
          return false;
        }

        return true;
      }

      if( null !== this.integerLessThan ) {

        if( this.integerLessThan < answer.value.value ) {

          return true;
        } else {

          return false;
        }
      }

      if( null !== this.integerGreaterThan ) {

        if( this.integerGreaterThan > answer.value.value ) {

          return true;
        } else {

          return false;
        }
      }
    }
  }

  private constructor( value: IValueCondition ) {

    super( value );
  }

}

