import {EvaluationSection} from "../../javascript.lib.mojo-base/model/module/EvaluationSection";
import {IQuestion, Question} from "../../javascript.lib.mojo-base/model/Question";
import {FacilityEnumeratedConstants} from "./FacilityEnumeratedConstants";
import {EnumeratedConstantReference} from "../../javascript.lib.mojo-base/model/EnumeratedConstant";
import {SectionQuestions2} from "../../javascript.lib.mojo-base/model/module/SectionQuestions2";
import {FacilityEvaluationSections2} from "./FacilityEvaluationSections2";
import { FacilityQuestions } from "./FacilityQuestions";
import {SectionIdentifiers} from "./SectionIdentifiers";


export class FacilityQuestions2 {


  public static readonly PARKING_AND_ENTRANCE_QUESTIONS: IQuestion[] = [


    //
    //
    // PARKING (row: 3)
    //
    //


    {
      key: 'Bkp11',
      label: 'Is there parking available for visitors/employees?',
      helpImage: null,
      helpImages: ['facilities-help-images.2022-08-05/Bkp11.jpg'],
      helpText: 'Any area provided by the company for staff or visitors to park cars or other vehicles.',
      popupLabel: 'Onsite Parking',
      type: Question.TYPE_ENUM,
      type2: {
        typeEnum: {
          options: [
            FacilityEnumeratedConstants.CAR_PARK_FACILITIES.no,
            FacilityEnumeratedConstants.CAR_PARK_FACILITIES.indoor,
            FacilityEnumeratedConstants.CAR_PARK_FACILITIES.outdoor,
            FacilityEnumeratedConstants.CAR_PARK_FACILITIES.both,
            FacilityEnumeratedConstants.CAR_PARK_FACILITIES.street,
          ],
          scoring: [
            // zero for everybody

            // {
            //   enumeration: FacilityEnumeratedConstants.CAR_PARK_FACILITIES.no,
            //   score: 0
            // },
            // {
            //   enumeration: FacilityEnumeratedConstants.CAR_PARK_FACILITIES.indoor,
            //   score: 0
            // },
            // {
            //   enumeration: FacilityEnumeratedConstants.CAR_PARK_FACILITIES.outdoor,
            //   score: 0
            // },
            // {
            //   enumeration: FacilityEnumeratedConstants.CAR_PARK_FACILITIES.both,
            //   score: 0
            // },
          ],
        }
      },
      maximumScore: 0,
      spreadsheetRow: 3,
      enumOptions: [
        FacilityEnumeratedConstants.CAR_PARK_FACILITIES.no,
        FacilityEnumeratedConstants.CAR_PARK_FACILITIES.indoor,
        FacilityEnumeratedConstants.CAR_PARK_FACILITIES.outdoor,
        FacilityEnumeratedConstants.CAR_PARK_FACILITIES.both,
        FacilityEnumeratedConstants.CAR_PARK_FACILITIES.street,
      ]
    },
    {
      key: 'MpjwN',
      label: 'Are parking charges in place?',
      helpImage: null,
      helpImages: [],
      helpText: 'If car parking is provided, is there a charge to park a vehicle on the grounds of your premises?',
      popupLabel: 'Parking Charges',
      type: Question.TYPE_BOOLEAN,
      maximumScore: 0,

      spreadsheetRow: null,
      dependant: {
        questionKey: 'Bkp11',
        truthyEnumCodes: [
          FacilityEnumeratedConstants.CAR_PARK_FACILITIES.indoor.codeAsNumber,
          FacilityEnumeratedConstants.CAR_PARK_FACILITIES.both.codeAsNumber,
          FacilityEnumeratedConstants.CAR_PARK_FACILITIES.outdoor.codeAsNumber,
        ]
      },
    },
    {
      key: 'Mpj2n',
      label: 'Are any parking payment machines set at a reachable height for wheelchair users and persons of shorter stature?',
      helpImage: null,
      helpImages: ['facilities-help-images.2022-08-05/Mpj2n.png'],
      helpText: 'All controls of parking payment machines should be at a lower height to enable wheelchair users or someone of short stature to reach the ticket dispenser, cash or card slots, and the intercom system.',
      popupLabel: 'Wheelchair Accessible Payment Machines',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 1,
            onFalse: 0,
          }
        }
      },
      maximumScore: 1,

      spreadsheetRow: null,
      dependant: {
        questionKey: 'MpjwN',
        // isEssential: true
      },
    },
    {
      key: 'BkqAn',
      label: 'Are there designated wheelchair accessible parking spaces available? \n',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/2.BkqAn.jpg'],
      helpText: 'Are there any large parking spaces designated for wheelchair users and people with limited mobility? These spaces should have sufficient space around them for easy access in and out of the vehicle.',
      popupLabel: 'Designated Accessible Parking Spaces ',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 2,
            onFalse: 0,
          }
        }
      },
      maximumScore: 2,

      spreadsheetRow: 4,
      dependant: {
        questionKey: 'Bkp11',
        isEssential: true,
        truthyEnumCodes: [
          FacilityEnumeratedConstants.CAR_PARK_FACILITIES.indoor.codeAsNumber,
          FacilityEnumeratedConstants.CAR_PARK_FACILITIES.outdoor.codeAsNumber,
          FacilityEnumeratedConstants.CAR_PARK_FACILITIES.both.codeAsNumber,
          FacilityEnumeratedConstants.CAR_PARK_FACILITIES.street.codeAsNumber,
        ]
      },

    },
    {
      key: 'BkqAx',
      label: 'How many wheelchair accessible parking spaces are provided?  ',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/1.BkqAx.jpg'],
      helpText: 'Ideally, a minimum of 1 in 25 spaces should be designated for drivers and passengers with disabilities.',
      popupLabel: 'Number Of Accessible Parking Spaces Available ',
      type: Question.TYPE_INTEGER,
      type2: {
        typeInteger: {
          minValue: 1,
          maxValue: Number.MAX_VALUE,
          scoring: [
            {
              greaterThanOrEqualTo: 1,
              lessThan: 2,
              score: 1,
            },
            {
              greaterThanOrEqualTo: 2,
              lessThan: 3,
              score: 2,
            },
            {
              greaterThanOrEqualTo: 3,
              score: 3,
            },
          ]
        }
      },
      maximumScore: 3,

      spreadsheetRow: 5,
      dependant: {
        questionKey: 'BkqAn',
        isEssential: true
      },
    },
    {
      key: 'BkqA7',
      label: 'Is there an accessible entrance from the indoor car park to the main building?  ',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/5.BkqA7.jpg'],
      helpText: 'From the indoor car park, is there a way to gain access into the building without the need to climb steps? This could be level access, ramp access, or access via a lift/elevator. ',
      popupLabel: 'Accessible Entrance From Indoor Car Park',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 2,
            onFalse: 0,
          }
        }
      },
      maximumScore: 2,

      spreadsheetRow: 6,
      dependant: {
        questionKey: 'Bkp11',
        // isEssential: true,
        truthyEnumCodes: [
          FacilityEnumeratedConstants.CAR_PARK_FACILITIES.indoor.codeAsNumber,
          FacilityEnumeratedConstants.CAR_PARK_FACILITIES.both.codeAsNumber,
        ]
      },
    },
    {
      key: 'BkqBF',
      label: 'From the car park, does the building entrance have an intercom or two-way communication system?',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/6.BkqBF.jpg'],
      helpText: 'Intercoms or two-way communication systems at the building entrance should support voice and a visual text display to allow people with hearing difficulties to read instructions on how to access the premises. ',
      popupLabel: 'Intercom At Entrance From Car Park ',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 1,
            onFalse: 0,
          }
        }
      },
      maximumScore: 1,

      spreadsheetRow: 7,
      dependant: {
        questionKey: 'Bkp11',
        truthyEnumCodes: [
          FacilityEnumeratedConstants.CAR_PARK_FACILITIES.indoor.codeAsNumber,
          FacilityEnumeratedConstants.CAR_PARK_FACILITIES.both.codeAsNumber,
        ]
      },
    },

    {
      key: 'ajdl0',
      label: 'Is there a step-free and unobstructed route from the designated accessible parking space(s) to the most accessible building entrance?',
      helpImage: null,
      helpImages: ['facilities-help-images.2022-08-05/ajdl0.png'],
      helpText: 'An unobstructed accessible route is a clear pathway without steps, poles, signposts, bins or other obstacles that might block or impede pedestrians using wheelchairs, strollers or walking aids. Note: Crossing points, footpaths or walkways that do not have a drop curb can also be considered an obstacle.',
      popupLabel: 'Accessible Route From Parking   ',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 2,
            onFalse: 0,
          }
        }
      },
      maximumScore: 2,

      spreadsheetRow: 7,
      dependant: {
        questionKey: 'BkqAn', // Are there designated wheelchair accessible parking spaces available?
        isEssential: true
        // truthyEnumCodes: [
        // ]
        // truthyBooleanValue: false
      },
    },



    {
      key: 'BkqBP',
      label: 'From the car park, is the entrance to the building through two doors in a series?',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/7.BkqBP.jpg'],
      helpText: 'Entrances through two doors in a series are characterised by two consecutive doors that have a clear space in between them, often referred to as \'lobby\' or \'vestibule\'. ',
      popupLabel: 'Two-Door Entry System From Car Park',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 0,
            onFalse: 0,
          }
        }
      },
      maximumScore: 0,

      spreadsheetRow: 8,
      dependant: {
        questionKey: 'Bkp11',
        truthyEnumCodes: [
          FacilityEnumeratedConstants.CAR_PARK_FACILITIES.indoor.codeAsNumber,
          FacilityEnumeratedConstants.CAR_PARK_FACILITIES.both.codeAsNumber,
        ]
      },
    },
    {
      key: 'BkqBZ',
      label: 'In centimetres, what is the distance between the two doors in the series? (ie. the clear space between the two doors). ',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/8.BkqBZ.jpg'],
      helpText: 'In centimetres, please measure the distance between the two doors in series, eg. lobby/vestibule. The recommended distance between the doors is 150cm. The minimum requirement is of at least 120cm plus the width of the doors when swinging into the vestibule space.',
      popupLabel: 'Distance Between Two Doors In A Series',
      type: Question.TYPE_CM_MEASUREMENT,
      type2: {
        typeCmMeasurement: {
          minValue: 60,
          maxValue: 2 * 1000,
          scoring: [
            {
              greaterThanOrEqualTo: 120,
              lessThan: 150,
              score: 2
            },
            {
              greaterThanOrEqualTo: 150,
              score: 3
            },
          ]
        }
      },
      maximumScore: 3,

      spreadsheetRow: 9,
      dependant: {
        // isEssential: true,
        questionKey: 'BkqBP',
      },
    },
    {
      key: 'BkqBj',
      label: 'Please take 2 photos of the route from the accessible car parking spaces to the entrance of the building - (in landscape mode)',
      helpImage: null,
      helpText: 'Take two photos of the route a visitor would take to get from the car park to the main entrance of the building. Be sure to capture a good view of the entrance from two different angles. ',
      popupLabel: '',
      type: Question.TYPE_PHOTO,
      maximumScore: 0,

      spreadsheetRow: 10,
      dependant: {
        questionKey: 'BkqAn',
      },
    },


    //
    //
    // Building Entrance (row: 11)
    //
    //

    {
      key: 'BkqBt',
      label: 'Is the alternative accessible entrance clearly signposted? ',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/17.BkqBt.png'],
      helpText: 'Are there prominent signs directing people to the accessible entrance into the building? (Ref: 2010 ADA Standards for Accessible Design - 206.6). \n',
      popupLabel: 'Alternative Accessible Entrance Clearly Signposted',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 1,
            onFalse: 0,
          }
        }
      },
      maximumScore: 1,

      spreadsheetRow: 12,
      dependant: {
        questionKey: 'BkqCV'
      },
    },
    {
      key: 'Cjk03',
      label: 'Please take 2 photos of the alternative accessible entrance (in landscape mode)',
      helpImage: null,
      helpText: '',
      popupLabel: '',
      type: Question.TYPE_PHOTO,
      maximumScore: 0,

      spreadsheetRow: -1,
      dependant: {
        questionKey: 'BkqCV'
      },
    },
    {
      key: 'BkqB3',
      label: 'Is there step-free access into the main building entrance? This could be level, ramp, or lift access.',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/13.BkqB3.png'],
      helpText: 'Is there a way to gain access into the building without the need to climb steps? This could be Level access, ramp access, or access via an External Platform Lift.    ',
      popupLabel: 'Step-Free Main Entrance',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 3,
            onFalse: 0,
          }
        }
      },
      maximumScore: 3,

      spreadsheetRow: 13,
    },
    {
      key: 'BkqCB',
      label: 'How many steps are there up to the main entrance?',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/Steps.BkqCB.png'],
      helpText: 'How many steps do you need to climb  to reach the main entrance door of your building?',
      popupLabel: 'Number Of Steps Into Building',
      type: Question.TYPE_INTEGER,
      type2: {
        typeInteger: {
          minValue: 0,
          maxValue: Number.MAX_VALUE,
          scoring: []
        }
      },
      maximumScore: 0,

      spreadsheetRow: 14,
      dependant: {
        questionKey: 'BkqB3',
        truthyBooleanValue: false
      }
    },


    {
      key: 'ajd8e',
      label: 'Are handrails provided at steps?',
      helpImage: null,
      helpImages: ['facilities-help-images.2022-08-05/ajd8e.svg'],
      helpText: 'Handrails can assist those with a visual or mobility impairment to safely move up the steps.',
      popupLabel: 'Handrails On Steps ',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 1,
            onFalse: 0,
          }
        }
      },
      maximumScore: 1,

      spreadsheetRow: 7,
      dependant: {
        questionKey: 'BkqB3', // Is there step-free access into the main building entrance? This could be level, ramp, or lift access.
        truthyBooleanValue: false,
      },
    },



    {
      key: 'BkqCL', // What type of door is there into the main entrance?
      label: 'What type of door is there into the main entrance?   ',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/Type-of-Door.BkqCL.png'],
      helpText: 'Is the entrance door fully automatic, activated through a push pad, revolving or manual? Choose the most applicable option.  ',
      popupLabel: '',
      type: Question.TYPE_ENUM,
      type2: {
        typeEnum: {
          options: [
            FacilityEnumeratedConstants.DOOR_TYPE.automatic,
            FacilityEnumeratedConstants.DOOR_TYPE.pushPad,
            FacilityEnumeratedConstants.DOOR_TYPE.manual,
            FacilityEnumeratedConstants.DOOR_TYPE.revolving,
          ],
          scoring: [
            {
              codeAsNumber: FacilityEnumeratedConstants.DOOR_TYPE.automatic.codeAsNumber,
              score: 3,
            },
            {
              codeAsNumber: FacilityEnumeratedConstants.DOOR_TYPE.pushPad.codeAsNumber,
              score: 2,
            },
            {
              codeAsNumber: FacilityEnumeratedConstants.DOOR_TYPE.manual.codeAsNumber,
              score: 1,
            },
            {
              codeAsNumber: FacilityEnumeratedConstants.DOOR_TYPE.revolving.codeAsNumber,
              score: 0,
            },
          ]
        }
      },
      maximumScore: 3,

      spreadsheetRow: 15,
      enumOptions: [
        FacilityEnumeratedConstants.DOOR_TYPE.automatic,
        FacilityEnumeratedConstants.DOOR_TYPE.manual,
        FacilityEnumeratedConstants.DOOR_TYPE.revolving,
      ]
    },


    {
      key: 'ajeoO', // How do the automatic doors open?
      label: 'How do the automatic doors open? ',
      helpImage: null,
      helpImages: ['facilities-help-images.2022-08-05/ajeoO.png'],
      helpText: '',
      popupLabel: '',
      type: Question.TYPE_ENUM,
      type2: {
        typeEnum: {
          options: [
            FacilityEnumeratedConstants.AUTOMATIC_DOOR_OPENING_TYPE.inOut,
            FacilityEnumeratedConstants.AUTOMATIC_DOOR_OPENING_TYPE.sideways,
          ],
          scoring: [
            {
              codeAsNumber: FacilityEnumeratedConstants.AUTOMATIC_DOOR_OPENING_TYPE.inOut.codeAsNumber,
              score: 0
            },
            {
              codeAsNumber: FacilityEnumeratedConstants.AUTOMATIC_DOOR_OPENING_TYPE.sideways.codeAsNumber,
              score: 3
            },
          ],
        }
      },
      maximumScore: 3,

      spreadsheetRow: 3,
      enumOptions: [
        FacilityEnumeratedConstants.AUTOMATIC_DOOR_OPENING_TYPE.inOut,
        FacilityEnumeratedConstants.AUTOMATIC_DOOR_OPENING_TYPE.sideways
      ],
      dependant: {
        questionKey: 'BkqCL',
        truthyEnumCodes: [
          FacilityEnumeratedConstants.DOOR_TYPE.automatic.codeAsNumber,
          // FacilityEnumeratedConstants.DOOR_TYPE.pushPad.codeAsNumber,
        ]
      },
    },

    {
      key: 'ajhxA',
      label: 'Do automatic doors that swing open have audible warnings?',
      helpImage: null,
      helpImages: ['facilities-help-images.2022-08-05/ajhxA.png'],
      helpText: 'Automated doors that swing open towards the user should have voice announcements. When doors are fully open, they should not block any other access route. ',
      popupLabel: 'Audible Warning For Automatic Doors',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 1,
            onFalse: 0,
          }
        }
      },
      maximumScore: 1,

      spreadsheetRow: 7,
      dependant: {
        questionKey: 'ajeoO',
        truthyEnumCodes: [
          FacilityEnumeratedConstants.AUTOMATIC_DOOR_OPENING_TYPE.inOut.codeAsNumber
        ]
      },
    },

    {
      key: 'aji50', // What type of door handles are on the entrance door?
      label: 'What type of door handles are on the entrance door?',
      helpImage: null,
      helpImages: ['facilities-help-images.2022-08-05/aji50.png'],
      helpText: 'Minimum manual effort should be required when opening and closing any doors. Round or oval type doorknobs are not suitable for operation by persons with disabilities.',
      popupLabel: '',
      type: Question.TYPE_ENUM,
      type2: {
        typeEnum: {
          options: [
            FacilityEnumeratedConstants.ENTRANCE_DOOR_HANDLE.push,
            FacilityEnumeratedConstants.ENTRANCE_DOOR_HANDLE.lever,
            FacilityEnumeratedConstants.ENTRANCE_DOOR_HANDLE.vertical,
            FacilityEnumeratedConstants.ENTRANCE_DOOR_HANDLE.round,
          ],
          scoring: [
            {
              codeAsNumber: FacilityEnumeratedConstants.ENTRANCE_DOOR_HANDLE.push.codeAsNumber,
              score: 2
            },
            {
              codeAsNumber: FacilityEnumeratedConstants.ENTRANCE_DOOR_HANDLE.lever.codeAsNumber,
              score: 1
            },
            {
              codeAsNumber: FacilityEnumeratedConstants.ENTRANCE_DOOR_HANDLE.vertical.codeAsNumber,
              score: 1
            },
            {
              codeAsNumber: FacilityEnumeratedConstants.ENTRANCE_DOOR_HANDLE.round.codeAsNumber,
              score: 0
            },
          ],
        }
      },
      maximumScore: 2,

      spreadsheetRow: 3,
      enumOptions: [
        FacilityEnumeratedConstants.ENTRANCE_DOOR_HANDLE.lever,
        FacilityEnumeratedConstants.ENTRANCE_DOOR_HANDLE.vertical,
        FacilityEnumeratedConstants.ENTRANCE_DOOR_HANDLE.round,
        FacilityEnumeratedConstants.ENTRANCE_DOOR_HANDLE.push,
      ],
      dependant: {
        questionKey: 'BkqCL', // What type of door is there into the main entrance?
        truthyEnumCodes: [
          FacilityEnumeratedConstants.DOOR_TYPE.manual.codeAsNumber
        ]
      },
    },


    {
      key: 'ajkEg',
      label: 'Can the entrance door be opened by someone with limited strength?',
      helpImage: null,
      helpImages: [],
      helpText: 'Doors should require no more than 5 lbs (22.2N) of force to open. Note: You can use a door force gauge/door pressure gauge to measure force. ',
      popupLabel: 'Easy-To-Open Entrance Door',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 1,
            onFalse: 0,
          }
        }
      },
      maximumScore: 1,

      spreadsheetRow: 7,
      dependant: {
        questionKey: 'BkqCL', // What type of door is there into the main entrance?
        truthyEnumCodes: [
          FacilityEnumeratedConstants.DOOR_TYPE.manual.codeAsNumber
        ]
      },
    },


    {
      key: 'BkqCV',
      label: 'Is there an alternative accessible entrance?  \n',
      helpImage: null,
      helpImages: ['facilities-help-images.2022-08-05/BkqCV.png'],
      helpText: 'Is there an alternative entrance to your building which provides ramp or lift access?',
      popupLabel: 'Alternative Accessible Entrance',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 0,
            onFalse: 0,
          }
        }
      },
      maximumScore: 0,

      spreadsheetRow: 16,
      dependant: {
        questionKey: 'BkqCL', // What type of door is there into the main entrance?
        truthyEnumCodes: [
          FacilityEnumeratedConstants.DOOR_TYPE.revolving.codeAsNumber
        ]
      },
    },
    {
      key: 'CjAUx',
      label: 'Is there step-free access into the alternative accessible entrance? This could be level, ramp, or lift access.',
      helpImage: null,
      helpImages: ['facilities-help-images.2022-08-05/CjAUx.png'],
      helpText: 'Is there a way to gain access into the building without the need to climb steps? This could be Level access, ramp access, or access via an External Platform Lift.    ',
      popupLabel: 'Step-Free Alternative Accessible Entrance',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 3,
            onFalse: 0,
          }
        }
      },
      maximumScore: 3,

      spreadsheetRow: -1,
      dependant: {
        questionKey: 'BkqCV'
      },
    },
    {
      key: 'CjEZ3', // What type of door is there into the alternative accessible entrance?
      label: 'What type of door is there into the alternative accessible entrance?   ',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/12.CjEZ3.png'],
      helpText: 'Is the entrance door fully automatic, activated through a push pad, revolving or manual? Choose the most applicable option.  ',
      popupLabel: '[Automatic, Manual, Revolving] door at alternative accessible entrance',
      type: Question.TYPE_ENUM,
      type2: {
        typeEnum: {
          options: [
            FacilityEnumeratedConstants.ALTERNATE_ENTRANCE_DOOR_TYPE.automatic,
            FacilityEnumeratedConstants.ALTERNATE_ENTRANCE_DOOR_TYPE.pushPad,
            FacilityEnumeratedConstants.ALTERNATE_ENTRANCE_DOOR_TYPE.manual,
            // FacilityEnumeratedConstants.ALTERNATE_ENTRANCE_DOOR_TYPE.revolving,
          ],
          scoring: [
            {
              codeAsNumber: FacilityEnumeratedConstants.ALTERNATE_ENTRANCE_DOOR_TYPE.automatic.codeAsNumber,
              score: 3,
            },
            // {
            //   enumeration: FacilityEnumeratedConstants.DOOR_TYPE.pushPad,
            //   score: 2,
            // },
            {
              codeAsNumber: FacilityEnumeratedConstants.ALTERNATE_ENTRANCE_DOOR_TYPE.manual.codeAsNumber,
              score: 1,
            },
            // {
            //   codeAsNumber: FacilityEnumeratedConstants.DOOR_TYPE.revolving.codeAsNumber,
            //   score: 0,
            // },
          ]
        }
      },
      maximumScore: 3,

      spreadsheetRow: -1,
      dependant: {
        questionKey: 'BkqCV'
      },
    },
    {
      key: 'CjFQt',
      label: 'What is the alternative accessible entrance door width measurement? (In cm)',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/14.CjFQt.jpg'],
      helpText: 'In centimetres, please measure the width of the alternative accessible entrance door to your building.',
      popupLabel: 'Alternative Accessible Entrance Door Width',
      type: Question.TYPE_CM_MEASUREMENT,
      type2: {
        typeCmMeasurement: {
          minValue: 60,
          maxValue: 2 * 1000,
          scoring: [
            {
              greaterThanOrEqualTo: 85,
              lessThan: 91,
              score: 2
            },
            {
              greaterThanOrEqualTo: 91,
              score: 3
            },
          ]
        }
      },
      maximumScore: 3,

      spreadsheetRow: -1,
      dependant: {
        questionKey: 'CjEZ3', // What type of door is there into the alternative accessible entrance?
        truthyEnumCodes: [
          FacilityEnumeratedConstants.DOOR_TYPE.automatic.codeAsNumber,
          // FacilityEnumeratedConstants.DOOR_TYPE.pushPad.codeAsNumber,
          FacilityEnumeratedConstants.DOOR_TYPE.manual.codeAsNumber,
        ]
      },
    },
    {
      key: 'BkqCf',
      label: 'What is the main entrance door width measurement? (In cm)',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/13.BkqCf.jpg'],
      helpText: 'In centimetres, please measure the width of the main entrance door to your building. If the doorway is made up of two doors, the open door should have a minimum clear width of 85cm.',
      popupLabel: 'Main Entrance Door Width ',
      type: Question.TYPE_CM_MEASUREMENT,
      type2: {
        typeCmMeasurement: {
          minValue: 60,
          maxValue: 2 * 1000,
          scoring: [
            {
              greaterThanOrEqualTo: 85,
              lessThan: 91,
              score: 2
            },
            {
              greaterThanOrEqualTo: 91,
              score: 3
            },
          ]
        }
      },
      maximumScore: 3,

      spreadsheetRow: 17,
      dependant: {
        questionKey: 'BkqCL',
        truthyEnumCodes: [
          FacilityEnumeratedConstants.DOOR_TYPE.automatic.codeAsNumber,
          // FacilityEnumeratedConstants.DOOR_TYPE.pushPad.codeAsNumber,
          FacilityEnumeratedConstants.DOOR_TYPE.manual.codeAsNumber,
        ]
      }
    },
    {
      key: 'BkqCp',
      label: 'Is the main entrance door made of full clear glass panels? ',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/13.BkqCp.png'],
      helpText: 'Are the main entrance doors made of large areas of clear glass or fully glazed doors?',
      popupLabel: 'Glass Entrance Doors',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 0,
            onFalse: 0,
          }
        }
      },
      maximumScore: 0,

      spreadsheetRow: 18,
    },
    {
      key: 'BkqCz',
      label: 'Do the glass doors have safety stickers or decals to prevent someone from walking into the glass?  ',
      helpImage: null,
      helpImages: ['facilities-help-images.2022-08-05/BkqCz.svg'],
      helpText: 'Clear glass can be particularly difficult for people with low vision who may not realize there is a barrier in the way. Using decals with dots or stripes to create a visual mark can help them distinguish the entrance.',
      popupLabel: 'Safety Stickers On Glass Doors ',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 2,
            onFalse: 0,
          }
        }
      },
      maximumScore: 2,

      spreadsheetRow: 19,
      dependant: {
        questionKey: 'BkqCp',
      }
    },
    {
      key: 'BkqC9',
      label: 'Please take 2 photos of the main entrance (in landscape mode)',
      helpImage: null,
      helpText: 'A good visual view of the outside entrance from two different angles.',
      popupLabel: '',
      type: Question.TYPE_PHOTO,
      maximumScore: 0,

      spreadsheetRow: 20,
    },

    //
    //
    // Reception area (row: 21)
    //
    //


    {
      key: 'Bkve7',
      label: 'Is there a lower counter/desk in the reception area?',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/18.Bkve7.png'],
      helpText: 'Is any part of the reception desk at a lower level for children, wheelchair users and people of short stature?  N/A means there is no reception counter/desk. ',
      popupLabel: 'Lower Counter/Desk ',
      type: Question.TYPE_TERNARY,
      type2: {
        typeTernary: {
          scoring: {
            onNotApplicable: 1,
            onTrue: 1,
          }
        }
      },
      maximumScore: 1,

      spreadsheetRow: 22,
    },
    {
      key: 'BkvfF',
      label: 'Are assistive listening systems provided at the reception area? (eg. Audio Induction Loop) ',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/19.BkvfF.png'],
      helpText: 'Assistive Listening or Hearing Enhancement Systems help amplify audible communication. There are hard-wired systems and three types of wireless systems: Induction loop, Infrared, and FM radio transmission.  These can be used by people who have a hearing impairment. ',
      popupLabel: 'Assistive Listening Systems',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 2,
            onFalse: 0,
          }
        }
      },
      maximumScore: 2,

      spreadsheetRow: 23,
      // dependant: {
      //   questionKey: 'Bkve7',
      //   truthyTernaryCodes: [
      //     EnumeratedConstantReference.no.codeAsNumber,
      //     EnumeratedConstantReference.yes.codeAsNumber,
      //   ],
      // },
    },
    {
      key: 'C9KCH',
      label: 'Is there visitor seating provided at the reception area?',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/20.C9KCH.png'],
      helpText: 'Are there any seats (eg. Chairs, couches, benches) available for people to rest from standing?',
      popupLabel: 'Seating Area',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 1,
            onFalse: 0,
          }
        }
      },
      maximumScore: 1,

      spreadsheetRow: -1,
      // dependant: {
      //   questionKey: 'Bkve7',
      //   truthyTernaryCodes: [
      //     EnumeratedConstantReference.no.codeAsNumber,
      //     EnumeratedConstantReference.yes.codeAsNumber,
      //   ],
      // },
    },
    {
      key: 'BkvfP',
      label: 'Is there directional and informational signage in the reception area? \n',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/21.BkvfP.png'],
      helpText: 'Directional and informational signage should be provided within the reception area. This signage uses easily recognised symbols and directional arrows to highlight key facilities such as lifts, stairs, toilets, and telephones. ',
      popupLabel: 'Directional And Informational Signage',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 1,
            onFalse: 0,
          }
        }
      },
      maximumScore: 1,

      spreadsheetRow: 24,
    },
    {
      key: 'Mpj2x',
      label: 'Are tactile maps available in the reception/lobby area?',
      helpImage: null,
      helpImages: ['facilities-help-images.2022-08-05/Mpj2x.png'],
      helpText: 'Tactile maps or floor plans are designed to be read by touch by people with vision impairments.',
      popupLabel: 'Tactile Maps ',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 2,
            onFalse: 0,
          }
        }
      },
      maximumScore: 2,

      spreadsheetRow: null,
    },
    {
      key: 'BkvfZ',
      label: 'Please take 2 photos of the reception area  1. Showing reception desk and 2. Directional signage if applicable (in landscape mode)',
      helpImage: null,
      helpText: '',
      popupLabel: '',
      type: Question.TYPE_PHOTO,
      maximumScore: 0,

      spreadsheetRow: 25,
    },
    {
      key: 'Bkvfj',
      label: 'Are guide/service dogs welcome on the premises?',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/23.Bkvfj.png'],
      helpText: 'Guide/service dogs are assistance dogs trained to lead blind and visually impaired people around obstacles.',
      popupLabel: 'Guide/Service Dogs Welcome',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 2,
            onFalse: 0,
          }
        }
      },
      maximumScore: 2,

      spreadsheetRow: 26,
    },
    {
      key: 'QZ_dz',
      label: 'Do you have any other comments or information about this area?',
      helpImage: null,
      helpText: 'Enter any additional information here or feedback. ',
      popupLabel: '',
      type: Question.TYPE_TEXT,
      maximumScore: 0,

      spreadsheetRow: -1,
    },

  ];
  public static readonly FACILITIES_AND_EVENT_SPACES_QUESTIONS: IQuestion[] = [

    //
    //
    // Facilities (row: 49)
    //
    //

    {
      key: 'BkyVv',
      label: 'Are baby changing facilities available on the premises? \n',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/38.BkyVv.svg'],
      helpText: 'Are baby changing facilities available in your building?\n',
      popupLabel: 'Baby Changing Facilities',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 2,
            onFalse: 0,
          }
        }
      },
      maximumScore: 2,

      spreadsheetRow: 42,
    },
    {
      key: 'BkyV5',
      label: 'Is there a baby feeding room on the premises?\n',
      helpImage: null,
      helpImages: ['facilities-help-images.2022-08-05/BkyV5.svg'],
      helpText: 'Is there a quiet room where nursing mothers can breastfeed their babies or express their milk?',
      popupLabel: 'Baby Feeding Room',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 1,
            onFalse: 0,
          }
        }
      },
      maximumScore: 1,

      spreadsheetRow: 43,
    },
    {
      key: 'BkyWD',
      label: 'Are there cloakrooms available on the premises?\n',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/40.BkyWD.svg'],
      helpText: 'Is there a cloakroom for people to hang their coats or other outerwear when they enter the building?\n',
      popupLabel: 'Cloakrooms ',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 0,
            onFalse: 0,
          }
        }
      },
      maximumScore: 0,

      spreadsheetRow: 44,
    },
    {
      key: 'BkyWN',
      label: 'Are some coat hooks reacheable from a seated position? ',
      helpImage: null,
      helpImages: ['facilities-help-images.2022-08-05/BkyWN.svg'],
      helpText: 'Accessible cloakrooms include wide entrance door, coat hooks set at different heights, and a full length mirror. ',
      popupLabel: 'Coat Hooks At Accessible Height',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 2,
            onFalse: 0,
          }
        }
      },
      maximumScore: 2,

      spreadsheetRow: 45,
      dependant: {
        questionKey: 'BkyWD',
      }
    },
    {
      key: 'BkyWX',
      label: 'Are there storage lockers available on the premises? \n',
      helpImage: null,
      helpImages: ['facilities-help-images.2022-08-05/BkyWX.svg'],
      helpText: 'Are there storage lockers or cabinet units for staff or guests to stow items?',
      popupLabel: 'Storage Lockers',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 0,
            onFalse: 0,
          }
        }
      },
      maximumScore: 0,

      spreadsheetRow: 46,
    },
    {
      key: 'BkyWh',
      label: 'Are there storage lockers available at varying heights? \n',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/41.BkyWh.svg'],
      helpText: 'Some storage lockers should be reacheable from a seated position. ',
      popupLabel: 'Storage Lockers At Accessible Height  ',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 1,
            onFalse: 0,
          }
        }
      },
      maximumScore: 1,

      spreadsheetRow: 47,
      dependant: {
        questionKey: 'BkyWX',
      }
    },
    {
      key: 'Mpj4z',
      label: 'Are there designated quiet spaces or rooms in the building?',
      helpImage: null,
      helpImages: ['facilities-help-images.2022-08-05/Mpj4z.svg'],
      helpText: 'A designated quiet room is a calm environment separate from the noise of the office. Ideally they should offer a relaxing tranquil space with soft ambient lighting. ',
      popupLabel: 'Designated Quiet Areas/Rooms ',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 2,
            onFalse: 0,
          }
        }
      },
      maximumScore: 2,

      spreadsheetRow: null,
    },


    {
      key: 'BkyWr',
      label: 'Are there gym facilities on the premises?\n',
      helpImage: null,
      helpImages: ['facilities-help-images.2022-08-05/BkyWr.svg'],
      helpText: 'Is there a place that houses gym and exercise equipment for staff to use?',
      popupLabel: 'Gym',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 0,
            onFalse: 0,
          }
        }
      },
      maximumScore: 0,

      spreadsheetRow: 48,
    },
    {
      key: 'BkyW1',
      label: 'Is there step-free access into the gym? This could be level, ramp, or lift access.',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/42.BkyW1.svg'],
      helpText: 'Step-free access could be level access, ramp access, or lift access. ',
      popupLabel: 'Step-Free Access To Gym',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 2,
            onFalse: 0,
          }
        }
      },
      maximumScore: 2,

      spreadsheetRow: 49,
      dependant: {
        questionKey: 'BkyWr'
      }
    },

    //
    //
    // Hallways & Corridors - Internal route of travel (row: 50)
    //
    //


    {
      key: 'Bky5D',
      label: 'Are all main routes clearly defined to assist the orientation of people with visual impairments? For example, by using colour contrast between wall and floor surfaces.',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/44.Bky5D.svg'],
      helpText: 'All main internal routes and corridors should be clearly defined. This can be achived through the use of appropriate floor, wall and ceiling materials and by ensuring that these colour contrast with  adjacent surfaces. Changing the texture of floor coverings is also a way to ensure internal routes are clearly distinguishable. Doing this will benefit people who are blind or partially sighted.',
      popupLabel: 'Colour Contrasting Walls And Floor Surfaces',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 2,
            onFalse: 0,
          }
        }
      },
      maximumScore: 2,

      spreadsheetRow: 51,
    },
    {
      key: 'Bky5N',
      label: 'Is there suitable and sufficient lighting providing good illumination throughout the building?',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/45.Bky5N.svg'],
      helpText: 'Lighting in buildings and corridors should be even, diffused, and without glare, reflections or shadows. The right lighting can help everyone, but especially people with reduced vision, to  move around the building safely and easily. ',
      popupLabel: 'Good Lighting Throughout The Building',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 2,
            onFalse: 0,
          }
        }
      },
      maximumScore: 2,

      spreadsheetRow: 52,
    },
    {
      key: 'Bky5X',
      label: 'Please take 1 photo of a typical main route/corridor (in landscape mode)',
      helpImage: null,
      helpText: 'Example of good visual contrast and width of corridor',
      popupLabel: '',
      type: Question.TYPE_PHOTO,
      maximumScore: 0,

      spreadsheetRow: 53,
    },

    //
    //
    // Food Services (row: 54)
    //
    //

    {
      key: 'BkzJT',
      label: 'Is there a cafeteria on the premises?\n',
      helpImage: null,
      helpImages: ['facilities-help-images.2022-08-05/BkzJT.svg'],
      helpText: 'Is there a food service location for eating that has tables and chairs?',
      popupLabel: 'On-site Cafeteria',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 0,
            onFalse: 0,
          }
        }
      },
      maximumScore: 0,

      spreadsheetRow: 55,
    },
    {
      key: 'BkzJd',
      label: 'Is there step-free access into the cafeteria? This could be level, ramp, or lift access.',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/47.BkzJd.svg'],
      helpText: 'Can the food and drinks area be accessed without steps?\n',
      popupLabel: 'Step-Free Access To Cafeteria ',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 3,
            onFalse: 0,
          }
        }
      },
      maximumScore: 3,

      spreadsheetRow: 56,
      dependant: {
        questionKey: 'BkzJT' // Is there a cafeteria on the premises?
      }
    },
    {
      key: 'Mpj27', // What type of cafeteria service is provided?
      label: 'What type of cafeteria service is provided?',
      helpImage: null,
      helpImages: [],
      helpText: 'Self service allows customers to gather their own food products and pay at a counter. Buffet service allows customers to choose from a selection of food options and be served along a tray slide counter. Counter service allows customers to order food and drink at a counter bar. Table service allows customers to place food orders at their table, without the need to stand to either order or collect their food.',
      popupLabel: 'Cafeteria Service',
      type: Question.TYPE_ENUM,
      type2: {
        typeEnum: {
          options: [
            FacilityEnumeratedConstants.CAFETERIA_TYPE.selfService,
            FacilityEnumeratedConstants.CAFETERIA_TYPE.buffetService,
            FacilityEnumeratedConstants.CAFETERIA_TYPE.counterService,
            FacilityEnumeratedConstants.CAFETERIA_TYPE.tableService,
          ],
          scoring: [
          ],
        }
      },
      maximumScore: 0,

      spreadsheetRow: null,
      dependant: {
        questionKey: 'BkzJT', // Is there a cafeteria on the premises?
      },
    },
    {
      key: 'Mpj3F',
      label: 'What is the height of the food counters from the floor to the top of the counter? ',
      helpImage: null,
      helpText: 'The maximum height of a food counter should be no higher than 86cm (34 inches) so someone in a seated position or someone of short stature can see and order whatever food or drinks are on offer.',
      popupLabel: 'Food Counter Height',
      type: Question.TYPE_INTEGER,
      type2: {
        typeInteger: {
          minValue: 0,
          maxValue: Number.MAX_VALUE,
          scoring: [
            {
              greaterThanOrEqualTo: 0,
              lessThan: 87,
              score: 2,
            }
          ]
        }
      },
      maximumScore: 2,

      spreadsheetRow: null,
      dependant: {
        questionKey: 'Mpj27', // What type of cafeteria service is provided?
        truthyEnumCodes: [
          FacilityEnumeratedConstants.CAFETERIA_TYPE.counterService.codeAsNumber,
          FacilityEnumeratedConstants.CAFETERIA_TYPE.buffetService.codeAsNumber,
          FacilityEnumeratedConstants.CAFETERIA_TYPE.selfService.codeAsNumber
        ]

      },
    },

    {
      key: 'BkzJn',
      label: 'Are the tables and chairs movable or fixed to the floor?\n',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/48.BkzJn.svg'],
      helpText: 'Tables and chairs should be movable to allow wheelchair users and people using mobility devices to circulate comfortably. N/A means there is no seating available in the cafeteria. ',
      popupLabel: 'Cafeteria tables & chairs',
      type: Question.TYPE_ENUM,
      type2: {
        typeEnum: {
          options: [
            FacilityEnumeratedConstants.FURNITURE_TYPE.movable,
            FacilityEnumeratedConstants.FURNITURE_TYPE.fixed,
            FacilityEnumeratedConstants.FURNITURE_TYPE.both,
            FacilityEnumeratedConstants.FURNITURE_TYPE.notApplicable,
          ],
          scoring: [
            {
              codeAsNumber: FacilityEnumeratedConstants.FURNITURE_TYPE.movable.codeAsNumber,
              score: 2,
            },
            {
              codeAsNumber: FacilityEnumeratedConstants.FURNITURE_TYPE.fixed.codeAsNumber,
              score: 0,
            },
            {
              codeAsNumber: FacilityEnumeratedConstants.FURNITURE_TYPE.both.codeAsNumber,
              score: 1,
            },
            {
              codeAsNumber: FacilityEnumeratedConstants.FURNITURE_TYPE.notApplicable.codeAsNumber,
              score: 2,
            },
          ]
        }
      },
      maximumScore: 2,

      spreadsheetRow: 57,
      dependant: {
        questionKey: 'BkzJT' // Is there a cafeteria on the premises?
      },
    },
    {
      key: 'Mpj3t',
      label: 'Is there at least 91cm of clear aisle space around some tables and chairs? ',
      helpImage: null,
      helpImages: ['facilities-help-images.2022-08-05/Mpj3t.png'],
      helpText: 'There should be at least 91cm of wide aisle space between some tables and chairs to allow a wheelchair user or someone using a mobility aid to circulate without obstructions. ',
      popupLabel: 'Wide Aisle Space',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 3,
            onFalse: 0,
          }
        }
      },
      maximumScore: 3,

      spreadsheetRow: null,
      dependant: {
        questionKey: 'BkzJn', // Are the tables and chairs movable or fixed to the floor?
        truthyEnumCodes: [
          FacilityEnumeratedConstants.FURNITURE_TYPE.movable.codeAsNumber,
          FacilityEnumeratedConstants.FURNITURE_TYPE.fixed.codeAsNumber,
          FacilityEnumeratedConstants.FURNITURE_TYPE.both.codeAsNumber
        ]
      },
    },

    {
      key: 'BkzJx',
      label: 'Do some tables have at least 70cm clear space underneath to accommodate a wheelchair user?\n',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/51.BkzJx.svg'],
      helpText: 'The table should have clear space of 70cm beneath, with legs at the four corners of the table, or of a pod-type, with one central support. \n',
      popupLabel: '70cm+ of Clear Space Under Tables',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 2,
            onFalse: 0,
          }
        }
      },
      maximumScore: 2,

      spreadsheetRow: 58,
      dependant: {
        questionKey: 'BkzJn', // Are the tables and chairs movable or fixed to the floor?
        truthyEnumCodes: [
          FacilityEnumeratedConstants.FURNITURE_TYPE.movable.codeAsNumber,
          FacilityEnumeratedConstants.FURNITURE_TYPE.fixed.codeAsNumber,
          FacilityEnumeratedConstants.FURNITURE_TYPE.both.codeAsNumber
        ]
      }
    },

    {
      key: 'BkzJw',
      label: 'Please take 3 photos of the cafeteria from different angles (in landscape mode)',
      helpImage: null,
      helpText: '',
      popupLabel: '',
      type: Question.TYPE_PHOTO,
      maximumScore: 0,

      spreadsheetRow: -1,
      dependant: {
        questionKey: 'BkzJT' // Is there a cafeteria on the premises?
      }
    },

    {
      key: 'Mpj33',
      label: 'Are there food display cabinets or shelves where food and drink products are on display for purchase?',
      helpImage: null,
      helpImages: ['facilities-help-images.2022-08-05/Mpj33.png'],
      helpText: 'Display cabinets are a convenient way to present food and drink items for people to pick what they want.',
      popupLabel: 'Food/Drinks Displays',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 0,
            onFalse: 0,
          }
        }
      },
      maximumScore: 0,

      spreadsheetRow: null,
      dependant: {
        questionKey: 'BkzJT' // Is there a cafeteria on the premises?
      }
    },
    {
      key: 'Mpj4B',
      label: 'Are the food and drink products displayed vertically on shelves and in cabinets?',
      helpImage: null,
      helpImages: ['facilities-help-images.2022-08-05/Mpj4B.png'],
      helpText: 'At least 50% of each type of product must be within reach for all customers. Displaying products in vertical columns allows greater access to items. E.g a wheelchair user or someone of short stature can choose across the range of items rather than needing to reach up or ask for assistance to get a specific product.',
      popupLabel: 'Vertical Display Of Food And Drink Products',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 3,
            onFalse: 0,
          }
        }
      },
      maximumScore: 3,

      spreadsheetRow: null,
      dependant: {
        questionKey: 'Mpj33' // Are there food display cabinets or shelves where food and drink products are on display for purchase?
      }
    },

    {
      key: 'ajlli',
      label: 'Are menus available in print? ',
      helpImage: null,
      helpImages: ['facilities-help-images.2022-08-05/ajlli.png'],
      helpText: '',
      popupLabel: 'Menu in Print',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 1,
            onFalse: 0,
          }
        }
      },
      maximumScore: 1,

      spreadsheetRow: 7,
      dependant: {
        questionKey: 'BkzJT' // Is there a cafeteria on the premises?
      }
    },

    {
      key: 'ajluw',
      label: 'Are menus available in larger print? ',
      helpImage: null,
      helpImages: ['facilities-help-images.2022-08-05/ajluw.png'],
      helpText: '',
      popupLabel: 'Menu in Larger Print ',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 1,
            onFalse: 0,
          }
        }
      },
      maximumScore: 1,

      spreadsheetRow: 7,
      dependant: {
        questionKey: 'BkzJT' // Is there a cafeteria on the premises?
      }
    },

    {
      key: 'ajl5E',
      label: 'Are menus available in digital format? ',
      helpImage: null,
      helpImages: ['facilities-help-images.2022-08-05/ajl5E.png'],
      helpText: '',
      popupLabel: 'Menu in Digital Format',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 1,
            onFalse: 0,
          }
        }
      },
      maximumScore: 1,

      spreadsheetRow: 7,
      dependant: {
        questionKey: 'BkzJT' // Is there a cafeteria on the premises?
      }
    },

    {
      key: 'ajmCw',
      label: 'Are menus available in braille? ',
      helpImage: null,
      helpImages: ['facilities-help-images.2022-08-05/ajmCw.png'],
      helpText: '',
      popupLabel: 'Menu in Braille',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 1,
            onFalse: 0,
          }
        }
      },
      maximumScore: 1,

      spreadsheetRow: 7,
      dependant: {
        questionKey: 'BkzJT' // Is there a cafeteria on the premises?
      }
    },


    {
      key: 'BkzJ7',
      label: 'Please take 3 photos of the most accessible breakout area from different angles (in landscape mode)',
      helpImage: null,
      helpText: 'Take 1 good quality photos of the most accessible breakout area.',
      popupLabel: '',
      type: Question.TYPE_PHOTO,
      maximumScore: 0,

      spreadsheetRow: 59,
      dependant: {
        questionKey: 'CmgRH' // Is there a breakout area with refreshment facilities in the building? (Kitchenette, water coolers, coffee machines, etc.)
      }
    },



    {
      key: 'QZ_lT',
      label: 'Is there a bar or lounge that serves beverages in the office?',
      helpImage: null,
      helpImages: [],
      helpText: 'Is there a bar area that serves alcoholic beverages in the office?',
      popupLabel: 'Bar/Lounge Area',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 0,
            onFalse: 0,
          }
        }
      },
      maximumScore: 0,

      spreadsheetRow: -1,
    },
    {
      key: 'QZ_ob',
      label: 'Is there step-free access into the bar/lounge? This could be level, ramp, or lift access.',
      helpImage: null,
      helpImages: [],
      helpText: 'Can the bar/drinks area be accessed without the need to use steps?',
      popupLabel: 'Step-Free Access',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 2,
            onFalse: 0,
          }
        }
      },
      maximumScore: 2,

      spreadsheetRow: -1,
      dependant: {
        questionKey: 'QZ_lT' // 'Is there a bar or lounge that serves alcoholic beverages in the office?'
      },
    },
    {
      key: 'QZ_pD',
      label: 'Is any part of the bar counter set at a lower height?',
      helpImage: null,
      helpImages: [],
      helpText: 'Is any part of the bar counter at a lower level for children, wheelchair users and people of short stature?',
      popupLabel: 'Lower Bar Counter',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 1,
            onFalse: 0,
          }
        }
      },
      maximumScore: 1,

      spreadsheetRow: -1,
      dependant: {
        questionKey: 'QZ_lT' // 'Is there a bar or lounge that serves alcoholic beverages in the office?'
      },
    },
    {
      key: 'QZ_p_', // In the bar/lounge area, are the tables and chairs movable or fixed to the floor?
      label: 'In the bar/lounge area, are the tables and chairs movable or fixed to the floor?',
      helpImage: null,
      helpImages: [],
      helpText: 'Is there flexible seating areas where furniture can be moved around if necessary to make room for a wheelchair user or other mobility devices?',
      popupLabel: 'tables & chairs',
      type: Question.TYPE_ENUM,
      type2: {
        typeEnum: {
          options: [
            FacilityEnumeratedConstants.FURNITURE_TYPE.movable,
            FacilityEnumeratedConstants.FURNITURE_TYPE.fixed,
            FacilityEnumeratedConstants.FURNITURE_TYPE.both,
          ],
          scoring: [
            {
              codeAsNumber: FacilityEnumeratedConstants.FURNITURE_TYPE.movable.codeAsNumber,
              score: 2,
            },
            {
              codeAsNumber: FacilityEnumeratedConstants.FURNITURE_TYPE.fixed.codeAsNumber,
              score: 0,
            },
            {
              codeAsNumber: FacilityEnumeratedConstants.FURNITURE_TYPE.both.codeAsNumber,
              score: 1,
            },
          ],
        }
      },
      maximumScore: 2,

      spreadsheetRow: -1,
      dependant: {
        questionKey: 'QZ_lT' // 'Is there a bar or lounge that serves alcoholic beverages in the office?'
      },
    },
    {
      key: 'QZ_qx',
      label: 'Are some lower tables provided where wheelchair users or persons of short stature can rest their plates and drinks?',
      helpImage: null,
      helpImages: [],
      helpText: 'A range of table height\'s should be available for any seated or standing user.',
      popupLabel: 'Tables at Accessible Height',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 1,
            onFalse: 0,
          }
        }
      },
      maximumScore: 1,

      spreadsheetRow: -1,
      dependant: {
        questionKey: 'QZ_lT' // 'Is there a bar or lounge that serves alcoholic beverages in the office?'
      },
    },
    {
      key: 'QZ_rZ',
      label: 'Is there at least 91cm of clear aisle space around some tables and chairs?',
      helpImage: null,
      helpImages: ['facilities-help-images.2022-08-05/QZ_rZ.png'],
      helpText: 'Providing clear routes (min. 91cm wide) around tables and a choice of seating type will accommodate a wide range of users.',
      popupLabel: 'Wide Aisle Space',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 2,
            onFalse: 0,
          }
        }
      },
      maximumScore: 2,

      spreadsheetRow: -1,
      dependant: {
        questionKey: 'QZ_lT' // 'Is there a bar or lounge that serves alcoholic beverages in the office?'
      },
    },
    {
      key: 'QZ_sf',
      label: 'Please take 2 photo of the most accessible bar or lounge area (in landscape mode)',
      helpImage: null,
      helpImages: [],
      helpText: 'Take 1 good quality photos of the accessible bar or lounge area',
      popupLabel: '',
      type: Question.TYPE_PHOTO,
      maximumScore: 0,

      spreadsheetRow: -1,
      dependant: {
        questionKey: 'QZ_lT' // 'Is there a bar or lounge that serves alcoholic beverages in the office?'
      },
    },






    {
      key: 'Mpj4L',
      label: 'Are there vending machines in the building?',
      helpImage: null,
      helpImages: ['facilities-help-images.2022-08-05/Mpj4L.svg'],
      helpText: 'A vending machine is an automatic dispenser for drinks, snacks and other food items. ',
      popupLabel: 'Vending Machines',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 0,
            onFalse: 0,
          }
        }
      },
      maximumScore: 0,

      spreadsheetRow: null,
    },
    {
      key: 'Mpj4V',
      label: 'Is the control panel on the vending machine operable from a seated position?',
      helpImage: null,
      helpImages: ['facilities-help-images.2022-08-05/Mpj4V.png'],
      helpText: 'All controls, buttons, and payment slots of the vending machine should be set at a height between 38cm and 122cm.',
      popupLabel: 'Wheelchair Accessible Vending Machine(s)',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 1,
            onFalse: 0,
          }
        }
      },
      maximumScore: 1,

      spreadsheetRow: null,
      dependant: {
        questionKey: 'Mpj4L'
      }
    },


    {
      key: 'CmgRH', // Is there a breakout area with refreshment facilities in the building? (Kitchenette, water coolers, coffee machines, etc.)
      label: 'Is there a breakout area with refreshment facilities in the building? (Kitchenette, water coolers, coffee machines, etc.)',
      helpImage: null,
      helpImages: ['facilities-help-images.2022-08-05/CmgRH.svg'],
      helpText: 'Breakout areas are spaces that are separate from the main office. They are used as a place for employees to relax, eat their lunch, avail of refreshments, and even hold informal meetings.',
      popupLabel: 'Refreshment Facilities  ',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 0,
            onFalse: 0,
          }
        }
      },
      maximumScore: 0,

      spreadsheetRow: -1,
    },
    {
      key: 'Cmgc1',
      label: 'Is there step-free access into some of the breakout areas? This could be level, ramp, or lift access.',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/52.Cmgc1.svg'],
      helpText: 'Some of the breakout areas should be accessible without the need to climb steps.  If the breakout area is not leveled, ramp or lift access should be provided. ',
      popupLabel: 'Step-Free Access To Breakout Areas',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 3,
            onFalse: 0,
          }
        }
      },
      maximumScore: 3,

      spreadsheetRow: -1,
      dependant: {
        questionKey: 'CmgRH'
      }
    },
    {
      key: 'CmgpB',
      label: 'Can all appliances be reached from a seated position in the most accessible breakout area? (Microwave, kettle, etc.)',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/53.CmgpB.svg'],
      helpText: 'Kitchen appliances should be within easy reach for someone in a seated position or persons of short stature. Counters and sinks should have a minimum height of 71cm (28 inches) and a maximum height of 86cm (34 inches). N/A means there are no appliances in the most accessible breakout area',
      popupLabel: 'Appliances At Accessible Height',
      type: Question.TYPE_TERNARY,
      type2: {
        typeTernary: {
          scoring: {
            onNotApplicable: 1,
            onTrue: 1,
          }
        }
      },
      maximumScore: 1,

      spreadsheetRow: -1,
      dependant: {
        questionKey: 'CmgRH'
      }
    },
    {
      key: 'CmgyZ',
      label: 'Can the sink be reached from a seated position in the most accessible breakout area? ',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/55.CmgyZ.svg'],
      helpText: 'The kitchen sink should have adequate space underneath or beside it to allow wheelchair users and persons of short stature to approach, reach, and use it comfortably. N/A means there is no sink in the breakout area.',
      popupLabel: 'Sink At Accessible Height',
      type: Question.TYPE_TERNARY,
      type2: {
        typeTernary: {
          scoring: {
            onNotApplicable: 1,
            onTrue: 1,
          }
        }
      },
      maximumScore: 1,

      spreadsheetRow: -1,
      dependant: {
        questionKey: 'CmgRH'
      }
    },
    {
      key: 'Cmg8t', // Can some cabinets or cupboards be reached from a seated position in the most accessible breakout area?
      label: 'Can some cabinets or cupboards be reached from a seated position in the most accessible breakout area?',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/54.Cmg8t.svg'],
      helpText: 'Are some cabinets or storage cupboards set at a lower height or underneath the countertop? Select N/A if there are no cabinets or cupboards in the most accessible breakout area.',
      popupLabel: 'Cupboards/Cabinets At Accessible Height',
      type: Question.TYPE_TERNARY,
      type2: {
        typeTernary: {
          scoring: {
            onNotApplicable: 1,
            onTrue: 1,
          }
        }
      },
      maximumScore: 1,

      spreadsheetRow: -1,
      dependant: {
        questionKey: 'CmgRH', // Is there a breakout area with refreshment facilities in the building? (Kitchenette, water coolers, coffee machines, etc.)
      }
    },
    {
      key: 'CmhaZ',
      label: 'Is the furniture moveable or fixed to the floor in the most accessible breakout area? (eg. Tables and chairs)',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/56.CmhaZ.svg'],
      helpText: 'Ensuring all tables and chairs are movable is best practice. This setting facilitates the accommodation of wheelchair users and of anyone using mobility devices. N/A means the most accessible breakout area doesn’t have any furniture.',
      popupLabel: 'Tables & chairs in breakout area',
      type: Question.TYPE_ENUM,
      type2: {
        typeEnum: {
          options: [
            FacilityEnumeratedConstants.FURNITURE_TYPE.movable,
            FacilityEnumeratedConstants.FURNITURE_TYPE.both,
            FacilityEnumeratedConstants.FURNITURE_TYPE.fixed,
            FacilityEnumeratedConstants.NOT_APPLICABLE,
          ],
          scoring: [
            {
              codeAsNumber: FacilityEnumeratedConstants.FURNITURE_TYPE.movable.codeAsNumber,
              score: 2,
            },
            {
              codeAsNumber: FacilityEnumeratedConstants.FURNITURE_TYPE.fixed.codeAsNumber,
              score: 0,
            },
            {
              codeAsNumber: FacilityEnumeratedConstants.FURNITURE_TYPE.both.codeAsNumber,
              score: 1,
            },
            {
              codeAsNumber: FacilityEnumeratedConstants.NOT_APPLICABLE.codeAsNumber,
              score: 2,
            },
          ],
        }
      },
      maximumScore: 2,

      spreadsheetRow: -1,
      dependant: {
        questionKey: 'CmgRH'
      }
    },
    {
      key: 'Cmie_',
      label: 'Do kitchen appliances colour contrast with countertops in the most accessible breakout area? ',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/53.Cmie_.svg'],
      helpText: 'Kitchen appliances such as kettles, microwaves, and coffee machines should be easily distinguishable from their background and other surrounding surfaces such as countertops. Colour contrasting kitchen elements are especially beneficial to those with vision impairments, helping them to locate different devices and to operate them with ease',
      popupLabel: 'Colour Contrasting Kitchen Appliances ',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 1,
            onFalse: 0,
          }
        }
      },
      maximumScore: 1,

      spreadsheetRow: -1,
      dependant: {
        questionKey: 'CmgpB', // Can all appliances be reached from a seated position in the most accessible breakout area? (Microwave, kettle, etc.)
        truthyTernaryCodes: [
          EnumeratedConstantReference.no.codeAsNumber,
          EnumeratedConstantReference.yes.codeAsNumber,
        ],
      }
    },
    {
      key: 'BkzKF',
      label: 'Are there flights of stairs within the building?\n',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/58.BkzKF.svg'],
      helpText: 'This could be a staircase, a stairwell, or a flight of stairs between one floor or landing and the next.',
      popupLabel: 'Stairs ',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 0,
            onFalse: 0,
          }
        }
      },
      maximumScore: 0,

      spreadsheetRow: 60,
      dependant: {
        questionKey: 'ajmd8', // Are all your facilities on the ground level without the need to use an elevator or stairs to get to any areas or amenities?
        truthyBooleanValue: false
      }
    },
    {
      key: 'BkzKP',
      label: 'Do the stairs have handrails that colour contrast with the surrounding walls?\n',
      helpImage: null,
      helpImages: ['facilities-help-images.2022-08-05/BkzKP.svg'],
      helpText: 'Handrails that colour contrast with the surrounding floor and walls can assist people with visual impairments. N/A means there are no handrails.',
      popupLabel: 'Colour Contrasting Handrails on Stairs',
      type: Question.TYPE_TERNARY,
      type2: {
        typeTernary: {
          scoring: {
            onNotApplicable: 2,
            onTrue: 2,
          }
        }
      },
      maximumScore: 2,

      spreadsheetRow: 61,
      dependant: {
        questionKey: 'BkzKF'
      },
    },


    {
      key: 'ajmM6',
      label: 'Are internal stairs well lit? ',
      helpImage: null,
      helpImages: ['facilities-help-images.2022-08-05/ajmM6.png'],
      helpText: 'Good lighting should be provided along the length and width of stairs so all users can perceive and use the steps easily and safely.',
      popupLabel: 'Good Lighting Levels On Internal Stairs ',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 2,
            onFalse: 0,
          }
        }
      },
      maximumScore: 2,

      spreadsheetRow: 7,
      dependant: {
        questionKey: 'BkzKF',
      },
    },


    {
      key: 'BkzeF',
      label: 'Are the first and last steps clearly marked with a different color or tactile floor warning indicators?  ',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/59.BkzeF.svg'],
      helpText: 'Warning Tactile Indicator should be installed in a raised grid pattern of studs or \'dots\' to warn blind and vision impaired pedestrians of nearby steps.',
      popupLabel: 'Floor Warning Indicators ',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 3,
            onFalse: 0,
          }
        }
      },
      maximumScore: 3,

      spreadsheetRow: 62,
      dependant: {
        questionKey: 'BkzKF' // Are there flights of stairs within the building?
      },
    },

    //
    //
    // Event Spaces (row: 63)
    //
    //




    {
      key: 'Bkzpp',
      label: 'Is there an auditorium in the building?\n',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/60.Bkzpp.svg'],
      helpText: 'An auditorium is a room built to enable an audience to hear and watch performances, lectures, talks and to participate in events. ',
      popupLabel: 'Auditorium ',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 0,
            onFalse: 0,
          }
        }
      },
      maximumScore: 0,

      spreadsheetRow: 64,
    },
    {
      key: 'Bkzpz',
      label: 'Is there a designated space for wheelchair users within the auditorium?      ',
      helpImage: null,
      helpImages: ['facilities-help-images.2022-08-05/Bkzpz.svg'],
      helpText: 'Wheelchair accessible spaces should always be provided on a level surface and have clear unobstructed access from the aisle or access route. Recommended width 90cm x 140cm depth.',
      popupLabel: 'Wheelchair Accessible Spaces',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 2,
            onFalse: 0,
          }
        }
      },
      maximumScore: 2,

      spreadsheetRow: 97,
      dependant: {
        questionKey: 'Bkzpp',
      },
    },
    {
      key: 'Mpj4f',
      label: 'Is there a clear line of sight from the wheelchair designated space to the presenter in the auditorium?',
      helpImage: null,
      helpImages: ['facilities-help-images.2022-08-05/Mpj4f.svg'],
      helpText: 'A clear sight line means an unobstructed view from the designated wheelchair seating area to the stage / presenter.  Obstructions could be columns, pillars, or people standing and blocking the line of sight.',
      popupLabel: 'Clear View From Wheelchair Accessible Spaces',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 2,
            onFalse: 0,
          }
        }
      },
      maximumScore: 2,

      spreadsheetRow: null,
      dependant: {
        questionKey: 'Bkzpz', // Is there a designated space for wheelchair users within the auditorium?
      },
    },
    {
      key: 'Bkzp9',
      label: 'Are assistive listening systems provided in the auditorium? (eg. Audio Induction Loop) ',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/19.Bkzp9.png'],
      helpText: 'Acoustics within auditoriums should facilitate clear verbal communication. Assistive listening devices (ALD) and hearing enhancement systems are frequently used in conjunction with hearing aids. For example, induction loops and infrared signal transmitting systems help people who use a hearing aid or loop listener to hear sounds more clearly.',
      popupLabel: 'Assistive Listening Systems',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 3,
            onFalse: 0,
          }
        }
      },
      maximumScore: 3,

      spreadsheetRow: 66,
      dependant: {
        questionKey: 'Bkzpp',
      },
    },
    {
      key: 'BkzqH',
      label: 'Is there a text display or subtitles option on presentation screens provided in the auditorium? ',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/62.BkzqH.svg'],
      helpText: 'Adding subtitles or closed captions to screens makes your presentation accessible to a larger audience, including people with hearing disabilities and those who speak languages other than the one in your presentation.',
      popupLabel: 'Text Display/Subtitles ',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 3,
            onFalse: 0,
          }
        }
      },
      maximumScore: 3,

      spreadsheetRow: 67,
      dependant: {
        questionKey: 'Bkzpp', // Is there an auditorium in the building?
      },
    },
    {
      key: 'Mpj4p',
      label: 'Is there space for a guide/service dog in the auditorium? ',
      helpImage: null,
      helpImages: ['facilities-help-images.2022-08-05/Mpj4p.png'],
      helpText: 'Guide/service dogs are trained working animals who are kept on a leash and need to accompany their owners in public areas. To allow them to stay close to their owners,  it is important that the auditorium provides sufficient space for these animals. ',
      popupLabel: 'Space For Service Dog In Auditorium',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 2,
            onFalse: 0,
          }
        }
      },
      maximumScore: 2,

      spreadsheetRow: null,
      dependant: {
        questionKey: 'Bkzpp', // Is there an auditorium in the building?
      },
    },
    {
      key: 'Bkzqb',
      label: 'Please take 2 photos of the auditorium from different angles (in landscape mode)',
      helpImage: null,
      helpText: '',
      popupLabel: '',
      type: Question.TYPE_PHOTO,
      maximumScore: 0,

      spreadsheetRow: 68,
      dependant: {
        questionKey: 'Bkzpp', // Is there an auditorium in the building?
      },
    },

    //
    //
    // Elevators/Lifts (row: 69)
    //
    //

    {
      key: 'ajmd8', // Are all your facilities on the ground level without the need to use an elevator or stairs to get to any areas or amenities?
      label: 'Are all your facilities on the ground level without the need to use an elevator or stairs to get to any areas or amenities?',
      helpImage: null,
      helpImages: [],
      helpText: 'Are all facilities available in your workplace located on the ground floor? Answer \'yes\' if your office is located on a single-story building, or if you occupy only the ground level of a multi-story building.',
      popupLabel: 'Ground Floor Site',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 0,
            onFalse: 0,
          }
        }
      },
      maximumScore: 0,

      spreadsheetRow: 7,
      // dependant: {
      //   questionKey: '',
      //   // truthyEnumCodes: [
      //   // ]
      //   // truthyBooleanValue: false
      // },
    },


    {
      key: 'Bkz__',
      label: 'Is there a lift/elevator in the building?\n',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/63.Bkz__.svg'],
      helpText: 'Is there a lift/elevator accessible to all relevant areas in the building? ',
      popupLabel: 'Lift/Elevator',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 0,
            onFalse: 0,
          }
        }
      },
      maximumScore: 0,

      spreadsheetRow: 70,
      dependant: {
        questionKey: 'ajmd8', // Are all your facilities on the ground level without the need to use an elevator or stairs to get to any areas or amenities?
        truthyBooleanValue: false
      }
    },
    {
      key: 'Bk0AJ',
      label: 'Is there a step-free route from the main accessible entrance of the building to the most accessible lift/elevator? This could be level, ramp, or lift access.',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/64.Bk0AJ.svg'],
      helpText: 'The access route to the lift/elevator should be free from barriers such as steps.',
      popupLabel: 'Step-Free Route From Entrance To Lift/Elevator',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 3,
            onFalse: 0,
          }
        }
      },
      maximumScore: 3,

      spreadsheetRow: 71,
      dependant: {
        questionKey: 'Bkz__', // Is there a lift/elevator in the building?
        isEssential: true,
      },
    },

    {
      key: 'ajmsU',
      label: 'Are the lift/elevator call buttons reachable from a seated position and free from obstructions?',
      helpImage: null,
      helpImages: ['facilities-help-images.2022-08-05/ajmsU.svg'],
      helpText: 'The space around elevator call buttons must remain free of obstructions including plants and other decorative elements that prevent wheelchair users and others from reaching the call buttons. ',
      popupLabel: 'Call Buttons At Accessible Height ',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 1,
            onFalse: 0,
          }
        }
      },
      maximumScore: 1,

      spreadsheetRow: 7,
      dependant: {
        questionKey: 'Bkz__', // Is there a lift/elevator in the building?
        isEssential: true,
      },
    },


    {
      key: 'ajm9M',
      label: 'Do the lift/elevator call buttons have visual indicators?',
      helpImage: null,
      helpImages: ['facilities-help-images.2022-08-05/ajm9M.png'],
      helpText: 'Lift/elevator call buttons should light up when activated to inform people with a hearing impairment that the buttons has been pressed successfully. ',
      popupLabel: 'Call Buttons With Visual Indicators',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 1,
            onFalse: 0,
          }
        }
      },
      maximumScore: 1,

      spreadsheetRow: 7,
      dependant: {
        questionKey: 'Bkz__', // Is there a lift/elevator in the building?
        isEssential: true,
      },
    },

    {
      key: 'ajnKo',
      label: 'Inside the lift/elevator, are there visual indicators showing the current floor position?',
      helpImage: null,
      helpImages: ['facilities-help-images.2022-08-05/ajnKo.png'],
      helpText: 'Visual indicators highlighting in which floor the lift/elevator is currently on should be positioned above the control panel. Alternatively, two indicators may be provided: One above the doors, and another at a lower level within the control panel.',
      popupLabel: 'Visual Indicator For Floor Positioning',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 1,
            onFalse: 0,
          }
        }
      },
      maximumScore: 1,

      spreadsheetRow: 7,
      dependant: {
        questionKey: 'Bkz__', // Is there a lift/elevator in the building?
        isEssential: true,
      },
    },



    {
      key: 'Bk0AT',
      label: 'What is the "door width" of the most accessible lift/elevator? (In cm)  ',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/65.Bk0AT.svg'],
      helpText: 'In centimetres, measure the door width of the most accessible lift/elevator. Please refer to the image below for measurement guidance.',
      popupLabel: 'Door Width',
      type: Question.TYPE_CM_MEASUREMENT,
      type2: {
        typeCmMeasurement: {
          minValue: 60,
          maxValue: 4 * 1000,
          scoring: [
            {
              greaterThanOrEqualTo: 90,
              score: 1
            },
          ]
        }
      },
      maximumScore: 1,

      spreadsheetRow: 72,
      dependant: {
        questionKey: 'Bkz__', // Is there a lift/elevator in the building?
        isEssential: true,
      },
    },
    {
      key: 'Bk0An',
      label: 'What is the "internal width" of the most accessible lift/elevator? (In cm)\n',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/65.Bk0An.svg'],
      helpText: 'In centimetres, measure the internal width of the most accessible lift/elevator. Please refer to the image below for measurement guidance.',
      popupLabel: 'Internal Width ',
      type: Question.TYPE_CM_MEASUREMENT,
      type2: {
        typeCmMeasurement: {
          minValue: 60,
          maxValue: 4 * 1000,
          scoring: [
            {
              greaterThanOrEqualTo: 180,
              score: 1
            },
          ]
        }
      },
      maximumScore: 1,

      spreadsheetRow: 73,
      dependant: {
        questionKey: 'Bkz__', // Is there a lift/elevator in the building?
        isEssential: true,
      },
    },
    {
      key: 'Bk0Ax',
      label: 'What is the "internal depth" of the most accessible lift/elevator? (In cm)\n',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/65.Bk0Ax.svg'],
      helpText: 'In centimetres, measure the internal depth of the most accessible lift/elevator. Please refer to the image below for measurement guidance’',
      popupLabel: 'Internal Depth',
      type: Question.TYPE_CM_MEASUREMENT,
      type2: {
        typeCmMeasurement: {
          minValue: 60,
          maxValue: 4 * 1000,
          scoring: [
            {
              greaterThanOrEqualTo: 180,
              score: 1
            },
          ]
        }
      },
      maximumScore: 1,

      spreadsheetRow: 74,
      dependant: {
        questionKey: 'Bkz__', // Is there a lift/elevator in the building?
        isEssential: true,
      },
    },
    {
      key: 'Bk0A7',
      label: 'Are there voice announcements for each floor in the most accessible lift/elevator?',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/66.Bk0A7.svg'],
      helpText: 'Is there spoken messages indicating floor number and other information to passengers in the lift/elevator.  ',
      popupLabel: 'Voice Announcements',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 2,
            onFalse: 0,
          }
        }
      },
      maximumScore: 2,

      spreadsheetRow: 75,
      dependant: {
        questionKey: 'Bkz__', // Is there a lift/elevator in the building?
        isEssential: true,
      },
    },
    {
      key: 'Bk0BF',
      label: 'Is there braille, raised numbers, or appropriate symbols on the buttons of the most accessible lift/elevator?',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/67.Bk0BF.svg'],
      helpText: 'The buttons on lift/elevators should contain raised characters, braille, or symbols to allow people with visual impairments to distinguish them by touch. ',
      popupLabel: 'Tactile Elevator Buttons',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 1,
            onFalse: 0,
          }
        }
      },
      maximumScore: 1,

      spreadsheetRow: 76,
      dependant: {
        questionKey: 'Bkz__', // Is there a lift/elevator in the building?
        isEssential: true,
      },
    },
    {
      key: 'CmlfV',
      label: 'Is there a colour contrasted alarm button in the most accessible lift/elevator?',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/68.CmlfV.svg'],
      helpText: 'The alarm button of the lift/elevators should colour contrast with its background. For example, the alarm button could be yellow or red.',
      popupLabel: 'Colour Contrasted Emergency Alarm Button ',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 1,
            onFalse: 0,
          }
        }
      },
      maximumScore: 1,

      spreadsheetRow: -1,
      dependant: {
        questionKey: 'Bkz__', // Is there a lift/elevator in the building?
        isEssential: true,
      }
    },

    {
      key: 'ajnX6',
      label: 'Does the emergency alarm in the lift/elevator have braille or raised letters?',
      helpImage: null,
      helpImages: [],
      helpText: 'The emergency alarm inside the lift/elevator should have braille or raised letters to allow someone with a visual impairment to identify it. ',
      popupLabel: 'Tactile Emergency Alarm Button',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 1,
            onFalse: 0,
          }
        }
      },
      maximumScore: 1,

      spreadsheetRow: 7,
      dependant: {
        questionKey: 'Bkz__', // Is there a lift/elevator in the building?
        isEssential: true,
      }
    },

    {
      key: 'XP_vG',
      label: 'Does your building have a designated emergency evacuation lift/elevator with standby power that can be used in the event of a fire?',
      helpImage: null,
      helpText: 'A designated emergency evacuation lift/elevator with standby power is a fire-protected lift capable of being used for evacuation purposes in the event of a fire and can be part of an accessible means of egress. ',
      popupLabel: 'Designated Evacuation Lift/Elevator',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 3,
            onFalse: 0,
          }
        }
      },
      maximumScore: 3,

      spreadsheetRow: null,
      dependant: {
        questionKey: 'ajmd8', // Are all your facilities on the ground level without the need to use an elevator or stairs to get to any areas or amenities?
        truthyBooleanValue: false,
        isEssential: true,
      }
    },
    {
      key: 'TgYOI',
      label: 'Is there clear signage to indicate that lift/elevators can or cannot be used in an emergency situation?',
      helpImage: null,
      helpImages: ['facilities-help-images.2022-08-05/TgYOI.png'],
      helpText: 'Some passenger lift/elevators have fire protection measures while others don\'t. Therefore, it\'s important to provide clear signage on whether your building\'s lift/elevator can or cannot be used in a fire-related emergency. ',
      popupLabel: 'Clear Signage To Indicate Lift/Elevator Availiability In An Emergency',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 3,
            onFalse: 0,
          }
        }
      },
      maximumScore: 3,

      spreadsheetRow: -1,
      dependant: {
        questionKey: 'Bkz__', // Is there a lift/elevator in the building?
        isEssential: true,
      },
    },


    {
      key: 'Bk0BP',
      label: 'Please take 1 photo of the most accessible lift/elevator with the doors open (in landscape mode)',
      helpImage: null,
      helpText: '',
      popupLabel: '',
      type: Question.TYPE_PHOTO,
      maximumScore: 0,

      spreadsheetRow: 77,
      dependant: {
        questionKey: 'Bkz__', // Is there a lift/elevator in the building?
      },
    },
    {
      key: 'QZ_t5',
      label: 'Do you have any other comments or information about this area?',
      helpImage: null,
      helpText: '',
      popupLabel: '',
      type: Question.TYPE_TEXT,
      maximumScore: 0,

      spreadsheetRow: -1,
    },



  ];
  public static readonly WORK_STATIONS_AND_ROOMS_QUESTIONS: IQuestion[] = [

    //
    //
    // Meeting rooms (row: 78)
    //
    //

    {
      key: 'Bk0fj',
      label: 'Are there meeting rooms on the premises?',
      helpImage: null,
      helpImages: ['facilities-help-images.2022-08-05/Bk0fj.svg'],
      helpText: 'Is there a designated serviced room to hold meetings?\n',
      popupLabel: 'Meeting Room(s) ',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 0,
            onFalse: 0,
          }
        }
      },
      maximumScore: 0,

      spreadsheetRow: 79,
    },
    {
      key: 'Bk0ft',
      label: 'How many of the meeting rooms are wheelchair accessible?',
      helpImage: null,
      helpImages: ['facilities-help-images.2022-08-05/Bk0ft.svg'],
      helpText: 'Accessible meeting rooms should have wide doorways and movable furniture.',
      popupLabel: 'Wheelchair Accessible Meeting Rooms',
      type: Question.TYPE_INTEGER,
      type2: {
        typeInteger: {
          minValue: 1,
          maxValue: Number.MAX_VALUE,
          scoring: [
            {
              greaterThanOrEqualTo: 1,
              score: 3,
            }
          ]
        }
      },
      maximumScore: 3,

      spreadsheetRow: 80,
      dependant: {
        questionKey: 'Bk0fj', // Are there meeting rooms on the premises?
      },
    },
    {
      key: 'Bk0f3',
      label: 'Is there braille, raised lettering, or appropriate symbols on the meeting room signage?',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/75.Bk0f3.svg'],
      helpText: 'Meeting room\'s signage should include tactile letters and/or braille to allow people with vision impairments to locate themselves. Select N/A if there is no meeting room signage.',
      popupLabel: 'Tactile Meeting Room Signage',
      type: Question.TYPE_TERNARY,
      type2: {
        typeTernary: {
          scoring: {
            onNotApplicable: 2,
            onTrue: 2,
            onFalse: 0,
          }
        }
      },
      maximumScore: 2,

      spreadsheetRow: 81,
      dependant: {
        questionKey: 'Bk0fj', // Do you have meeting rooms on the premises?
      },
    },
    {
      key: 'Bk0gB',
      label: 'What is the door width measurement of the most accessible meeting room? (In cm)\n',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/17.Bk0gB.jpg'],
      helpText: 'Please measure the door width of the most accessible meeting room.  (Doorway should provide a minimum clear opening width of 81cm). ',
      popupLabel: 'Door Width Measurement ',
      type: Question.TYPE_CM_MEASUREMENT,
      type2: {
        typeCmMeasurement: {
          minValue: 60,
          maxValue: 4 * 1000,
          scoring: [
            {
              greaterThanOrEqualTo: 81,
              lessThan: 91,
              score: 2
            },
            {
              greaterThanOrEqualTo: 91,
              score: 3
            },
          ]
        }
      },
      maximumScore: 3,

      spreadsheetRow: 82,
      dependant: {
        questionKey: 'Bk0ft', // How many wheelchair accessible meeting rooms are there on the premises?
      },
    },
    {
      key: 'Bk0gL',
      label: 'Can the door to the most accessible meeting room be opened by someone with limited strength?\n',
      helpImage: null,
      helpText: 'Accessible doors should require no more than 5 lbs (22.2N) of force to open. Note: You can use a pressure gauge to measure force. If you do not have one you will need to judge whether the door is easy to open. N/A means the meeting room doesn\'t have a door.',
      popupLabel: 'Easy-To-Open Door',
      type: Question.TYPE_TERNARY,
      type2: {
        typeTernary: {
          scoring: {
            onNotApplicable: 1,
            onTrue: 1,
          }
        }
      },
      maximumScore: 1,

      spreadsheetRow: 83,
      dependant: {
        questionKey: 'Bk0ft', // How many wheelchair accessible meeting rooms are there on the premises?
      },
    },
    {
      key: 'Bk0gV', // Is the furniture moveable or fixed to the floor in the most accessible meeting room? (eg. Tables and chairs)
      label: 'Is the furniture moveable or fixed to the floor in the most accessible meeting room? (eg. Tables and chairs)',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/76.Bk0gV.svg'],
      helpText: 'Can some furniture be moved to make space for a wheelchair user?  Tables should have legs that do not protrude into the circulation area or block access for a person using a wheelchair to position beneath the table.',
      popupLabel: 'Furniture in wheelchair accessible meeting room ',
      type: Question.TYPE_ENUM,
      type2: {
        typeEnum: {
          options: [
            FacilityEnumeratedConstants.NOT_APPLICABLE,
            FacilityEnumeratedConstants.FURNITURE_TYPE.movable,
            FacilityEnumeratedConstants.FURNITURE_TYPE.both,
            FacilityEnumeratedConstants.FURNITURE_TYPE.fixed,
          ],
          scoring: [
            {
              codeAsNumber: FacilityEnumeratedConstants.FURNITURE_TYPE.movable.codeAsNumber,
              score: 2,
            },
            {
              codeAsNumber: FacilityEnumeratedConstants.FURNITURE_TYPE.both.codeAsNumber,
              score: 1,
            },
            {
              codeAsNumber: FacilityEnumeratedConstants.FURNITURE_TYPE.fixed.codeAsNumber,
              score: 0,
            },
            {
              codeAsNumber: FacilityEnumeratedConstants.NOT_APPLICABLE.codeAsNumber,
              score: 0,
            },
          ],
        }
      },
      maximumScore: 2,

      spreadsheetRow: 84,
      dependant: {
        questionKey: 'Bk0ft', // How many wheelchair accessible meeting rooms are there on the premises?
      },
    },
    {
      key: 'eau2O',
      label: 'Is there at least 91cm of clear aisle space around some tables and chairs?',
      helpImage: null,
      helpImages: ['facilities-help-images.2022-08-05/Mpj3t.png'],
      helpText: 'There should be at least 91cm of wide aisle space between some tables and chairs to allow a wheelchair user or someone using  a mobility aid to circulate without obstructions.',
      popupLabel: ' Wide Aisle Space',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 3,
            onFalse: 0,
          }
        }
      },
      maximumScore: 3,

      spreadsheetRow: null,
      dependant: {
        questionKey: 'Bk0fj', // Do you have meeting rooms on the premises?
      },
    },

    {
      key: 'Bk0gf',
      label: 'Are assistive listening systems provided in any of the meeting rooms? (eg. Audio Induction Loop)',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/19.Bk0gf.png'],
      helpText: 'Assistive listening devices (ALD) and hearing enhancement systems are frequently used in conjunction with hearing aids. For example induction loops and infrared signal transmitting systems help people who use a hearing aid or loop listener to hear sounds more clearly.',
      popupLabel: 'Assistive Listening Systems',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 3,
            onFalse: 0,
          }
        }
      },
      maximumScore: 3,

      spreadsheetRow: 85,
      dependant: {
        questionKey: 'Bk0fj', // Do you have meeting rooms on the premises?
      },
    },
    {
      key: 'Cmlsx',
      label: 'Do you have captioned performance or speech-to-text subtitles available on screen displays in any of the meeting rooms?',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/62.Cmlsx.svg'],
      helpText: 'Subtitles are on-screen representations of dialogue taking place on video. Subtitles or closed captioning can benefit a wide range of people, including those with hearing difficulties. It helps viewers to understand the flow of conversations, especially when there is a lot of noise and other distractions in the background. ',
      popupLabel: 'Captioned Performance/Speech-To-Text Subtitles ',
      type: Question.TYPE_TERNARY,
      type2: {
        typeTernary: {
          scoring: {
            onNotApplicable: 3,
            onTrue: 3,
          }
        }
      },
      maximumScore: 3,

      spreadsheetRow: -1,
      dependant: {
        questionKey: 'Bk0fj'
      }
    },

    {
      key: 'Bk0gp',
      label: 'Please take 1 photo of the most accessible meeting room (in landscape mode)',
      helpImage: null,
      helpText: '',
      popupLabel: '',
      type: Question.TYPE_PHOTO,
      maximumScore: 0,

      spreadsheetRow: 86,
      dependant: {
        questionKey: 'Bk0ft', // How many wheelchair accessible meeting rooms are there on the premises?
      },
    },

    //
    //
    // Training Rooms (row: 87)
    //
    //

    {
      key: 'Bk1dH',
      label: 'Do you have training rooms which are separate from your meeting rooms?\n',
      helpImage: null,
      helpImages: ['facilities-help-images.2022-08-05/Bk1dH.svg'],
      helpText: 'A training session generally occurs in a larger room, with several attendees. It requires clear and unobstructed views of the head of the session as well as of any screens and other visual aids used.\n',
      popupLabel: 'Training Room(s)',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 0,
            onFalse: 0,
          }
        }
      },
      maximumScore: 0,

      spreadsheetRow: 88,
    },
    {
      key: 'Bk1dR',
      label: 'How many wheelchair accessible training rooms are there on the premises?\n',
      helpImage: null,
      helpImages: ['facilities-help-images.2022-08-05/Bk1dR.svg'],
      helpText: 'Accessible training rooms should have wide doorways and movable furniture.',
      popupLabel: 'Wheelchair Accessible Training Rooms',
      type: Question.TYPE_INTEGER,
      type2: {
        typeInteger: {
          minValue: 1,
          maxValue: Number.MAX_VALUE,
          scoring: [
            {
              greaterThanOrEqualTo: 1,
              score: 3,
            }
          ]
        }
      },
      maximumScore: 3,

      spreadsheetRow: 89,
      dependant: {
        questionKey: 'Bk1dH', // Do you have training rooms which are separate from your meeting rooms?
      },
    },
    {
      key: 'Bk1db',
      label: 'Is there braille, raised lettering, or appropriate symbols on the training room signage?',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/75.Bk1db.svg'],
      helpText: 'Training room\'s signage should include tactile letters and/or braille to allow people with vision impairments to locate themselves. N/A means there is no training room signage.',
      popupLabel: 'Tactile Training Room Signage ',
      type: Question.TYPE_TERNARY,
      type2: {
        typeTernary: {
          scoring: {
            onNotApplicable: 2,
            onTrue: 2,
            onFalse: 0,
          }
        }
      },
      maximumScore: 2,

      spreadsheetRow: 90,
      dependant: {
        questionKey: 'Bk1dH', //Do you have training rooms which are separate from your meeting rooms?
      },
    },
    {
      key: 'Bk1dl',
      label: 'What is the width of the most accessible training room door? (In cm) \n',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/18.Bk1dl.jpg'],
      helpText: 'Please measure the door width of the most accessible training room.  (Doorway should provide a minimum clear opening width of 81cm).',
      popupLabel: 'Door Width Measurement',
      type: Question.TYPE_CM_MEASUREMENT,
      type2: {
        typeCmMeasurement: {
          minValue: 60,
          maxValue: 4 * 1000,
          scoring: [
            {
              greaterThanOrEqualTo: 91,
              score: 3
            },
            {
              greaterThanOrEqualTo: 81,
              lessThan: 91,
              score: 2
            },
          ]
        }
      },
      maximumScore: 3,

      spreadsheetRow: 91,
      dependant: {
        questionKey: 'Bk1dH', // Do you have training rooms which are separate from your meeting rooms?
      },
    },
    {
      key: 'Bk1dv',
      label: 'Can the door to the most accessible training room be opened by someone with limited strength?\n',
      helpImage: null,
      helpText: 'Accessible doors should require no more than 5 lbs (22.2N) of force to open. Note: You can use a pressure gauge to measure force. If you do not have one you will need to judge whether the door is easy to open. Select N/A if the training room doesn\'t have a door.',
      popupLabel: 'Easy-To-Open Door',
      type: Question.TYPE_TERNARY,
      type2: {
        typeTernary: {
          scoring: {
            onNotApplicable: 1,
            onTrue: 1,
          }
        }
      },
      maximumScore: 1,

      spreadsheetRow: 92,
      dependant: {
        questionKey: 'Bk1dH', // Do you have training rooms which are separate from your meeting rooms?
      },
    },
    {
      key: 'Bk1d5', // Is the furniture moveable or fixed to the floor in the most accessible training room? (eg. Tables and chairs)
      label: 'Is the furniture moveable or fixed to the floor in the most accessible training room? (eg. Tables and chairs)',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/76.Bk1d5.svg'],
      helpText: 'Can some furniture be moved to make space for a wheelchair user?  Tables should have legs that do not protrude into the circulation area or block access for a person using a wheelchair to position beneath the table.',
      popupLabel: 'Movable Furniture',
      type: Question.TYPE_ENUM,
      type2: {
        typeEnum: {
          options: [
            FacilityEnumeratedConstants.FURNITURE_TYPE.movable,
            FacilityEnumeratedConstants.FURNITURE_TYPE.both,
            FacilityEnumeratedConstants.FURNITURE_TYPE.fixed,
            FacilityEnumeratedConstants.NOT_APPLICABLE,
          ],
          scoring: [
            {
              codeAsNumber:  FacilityEnumeratedConstants.FURNITURE_TYPE.movable.codeAsNumber,
              score: 2,
            },
            {
              codeAsNumber:  FacilityEnumeratedConstants.FURNITURE_TYPE.fixed.codeAsNumber,
              score: 0,
            },
            {
              codeAsNumber:  FacilityEnumeratedConstants.FURNITURE_TYPE.both.codeAsNumber,
              score: 1,
            },
            {
              codeAsNumber:  FacilityEnumeratedConstants.NOT_APPLICABLE.codeAsNumber,
              score: 0,
            },
          ]
        }
      },
      maximumScore: 2,

      spreadsheetRow: 93,
      dependant: {
        questionKey: 'Bk1dH', // Do you have training rooms which are separate from your meeting rooms?
      },
    },
    {
      key: 'eau3K',
      label: 'Is there at least 91cm of clear aisle space around some tables and chairs?',
      helpImage: null,
      helpImages: ['facilities-help-images.2022-08-05/Mpj3t.png'],
      helpText: 'There should be at least 91cm of wide aisle space between some tables and chairs to allow a wheelchair user or someone using a mobility aid to circulate without obstructions. ',
      popupLabel: ' Wide Aisle Space',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 3,
            onFalse: 0,
          }
        }
      },
      maximumScore: 3,

      spreadsheetRow: null,
      dependant: {
        questionKey: 'Bk1dH', // Do you have training rooms which are separate from your meeting rooms?
      },
    },


    {
      key: 'Bk1eD',
      label: 'Are assistive listening systems provided in any of the training rooms? (eg. Audio Induction Loop)',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/19.Bk1eD.png'],
      helpText: 'Assistive listening devices (ALD) and hearing enhancement systems are frequently used in conjunction with hearing aids. For example induction loops and infrared signal transmitting systems help people who use a hearing aid or loop listener to hear sounds more clearly.',
      popupLabel: 'Assistive Listening Systems',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 3,
            onFalse: 0,
          }
        }
      },
      maximumScore: 3,

      spreadsheetRow: 94,
      dependant: {
        questionKey: 'Bk1dH', // Do you have training rooms which are separate from your meeting rooms?
      },
    },
    {
      key: 'Bk1eN',
      label: 'Do you have captioned performance or speech-to-text subtitles available on screen displays in any of the training rooms?',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/62.Bk1eN.svg'],
      helpText: 'Subtitles are on-screen representations of dialogue taking place on video. Subtitles or closed captioning can benefit a wide range of people, including those with hearing difficulties. It helps viewers to understand the flow of conversations, especially when there is a lot of noise and other distractions in the background. ',
      popupLabel: 'Captioned Performance/Speech-To-Text Subtitles ',
      type: Question.TYPE_TERNARY,
      type2: {
        typeTernary: {
          scoring: {
            onNotApplicable: 3,
            onTrue: 3,
          }
        }
      },
      maximumScore: 3,

      spreadsheetRow: 95,
      dependant: {
        questionKey: 'Bk1dH', // Do you have training rooms which are separate from your meeting rooms?
      },
    },
    {
      key: 'Bk1eX',
      label: 'Please take 1 photo of the most accessible training room (in landscape mode)',
      helpImage: null,
      helpText: '',
      popupLabel: '',
      type: Question.TYPE_PHOTO,
      maximumScore: 0,

      spreadsheetRow: 96,
      dependant: {
        questionKey: 'Bk1dR', // How many wheelchair accessible training rooms are there on the premises?
      },
    },

    //
    //
    // Office Workstations (row: 97)
    //
    //

    {
      key: 'Bk2BD',
      label: 'Are there different floor surfaces around open-plan areas and work stations that can aid orientation for people with visual impairments?',
      helpImage: null,
      helpImages: ['facilities-help-images.2022-08-05/Bk2BD.svg'],
      helpText: 'Different floor surfaces/floor colours can aid orientation, acoustic conditions, and ease of passage for wheelchair users and people with sight impairments. Floor surfaces should be firm to allow for easy passage. Floor surfaces should be slip-resistant and not cause reflection or glare. Large repeat floor patterns should be avoided.  Carpets, where used, should be of shallow dense pile.',
      popupLabel: 'Colour Contrasting Floor Surfaces',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 2,
            onFalse: 0,
          }
        }
      },
      maximumScore: 2,

      spreadsheetRow: 98,
    },
    {
      key: 'Bk2BN',
      label: 'Does the furniture in the office colour contrast with surrounding surfaces such as the walls and floor?  (eg. Couches, chairs, tables, etc.) ',
      helpImage: null,
      helpImages: ['facilities-help-images.2022-08-05/Bk2BN.svg'],
      helpText: 'Strong colour contrast between two items is especially helpful for people who are visually impaired and can help them navigate their surroundings.',
      popupLabel: 'Colour Contrasting Furniture',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 2,
            onFalse: 0,
          }
        }
      },
      maximumScore: 2,

      spreadsheetRow: 99,
    },
    {
      key: 'Bk2BX',
      label: 'Are some switches and sockets reachable from a seated position?',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/72.Bk2BX.svg'],
      helpText: 'Ease of reach is important for a wide range of people. Electrical wall sockets should be located at a minimum of 40cm and a maximum 100cm above floor level and 50cm from any corner.',
      popupLabel: 'Switches And Sockets At Accessible Height',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 1,
            onFalse: 0,
          }
        }
      },
      maximumScore: 1,

      spreadsheetRow: 100,
    },
    {
      key: 'Bk2Bh',
      label: 'Do some switches and sockets colour contrast to the background which they are mounted against?',
      helpImage: null,
      helpImages: ['facilities-help-images.2022-08-05/Bk2Bh.png'],
      helpText: 'Electrical sockets should colour contrast to the surrounding background against which they are mounted. ',
      popupLabel: 'Colour Contrasting Switches And Sockets',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 1,
            onFalse: 0,
          }
        }
      },
      maximumScore: 1,

      spreadsheetRow: 101,
    },
    {
      key: 'Bk2Br',
      label: 'Are some office desks height adjustable?\n',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/71.Bk2Br.svg'],
      helpText: 'Having at least 70cm of clear space under work surfaces/desks is recommended. Consider providing work surfaces that are flexible and permit installation at variable heights and clearances. ',
      popupLabel: 'Height Adjustable Desks',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 3,
            onFalse: 0,
          }
        }
      },
      maximumScore: 3,

      spreadsheetRow: 102,
    },
    {
      key: 'Bk2B1',
      label: 'Please take 2 photos of the most accessible office workstation area (in landscape mode)\n',
      helpImage: null,
      helpText: '',
      popupLabel: '',
      type: Question.TYPE_PHOTO,
      maximumScore: 0,

      spreadsheetRow: 103,
    },
    {
      key: 'Bk2B_',
      label: 'Are some printers and photocopying machines operable from a seated position?',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/74.Bk2B_.svg'],
      helpText: 'The preferred height for operation of a printer or photocopying machine by a person in a seated position is 76cm from floor level. A minimum of 50cm from a corner is recommended. N/A means the office doesn\'t have printers or photocopying machines. ',
      popupLabel: 'Wheelchair Accessible Printers',
      type: Question.TYPE_TERNARY,
      type2: {
        typeTernary: {
          scoring: {
            onNotApplicable: 1,
            onTrue: 1,
          }
        }
      },
      maximumScore: 1,

      spreadsheetRow: 104,
    },
    {
      key: 'QZ_yl',
      label: 'Do you have any other comments or information about this area?',
      helpImage: null,
      helpText: '',
      popupLabel: '',
      type: Question.TYPE_TEXT,
      maximumScore: 0,

      spreadsheetRow: -1,
    },




  ];
  public static readonly ACCESSIBLE_BATHROOMS_QUESTIONS: IQuestion[] = [


    {
      key: 'Bkvf3',
      label: 'Do the general bathrooms have braille, raised lettering, or appropriate symbols to identify the bathroom type? ',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/24.Bkvf3.png'],
      helpText: 'Braille is a form of written language for blind people, in which characters are represented by patterns of raised dots that are felt with the fingertips. Raised (tactile) male/female/unisex/accessibility symbols should be included in the general bathrooms\' signage. ',
      popupLabel: 'Tactile Signage At Entrance',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 2,
            onFalse: 0,
          }
        }
      },
      maximumScore: 2,

      spreadsheetRow: 28,
    },

    //
    //
    // Bathroom Facilities (row: 105)
    //
    //
    {
      key: 'Bk2e5',
      label: 'Is there a wheelchair accessible bathroom on the premises for employees?',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/30.Bk2e5.png'],
      helpText: 'Is there a wheelchair accessible bathroom for employees? The minimum size of the accessible WC cubicle should be 180cm x 250cm.',
      popupLabel: 'Wheelchair Accessible Bathroom',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 3,
            onFalse: 0,
          }
        }
      },
      maximumScore: 3,

      spreadsheetRow: 106,
    },
    {
      key: 'Bk2fD',
      label: 'How many wheelchair accessible bathrooms are on the premises?           \n',
      helpImage: null,
      helpImages: ['facilities-help-images.2022-08-05/Bk2fD.png'],
      helpText: 'What is the number of wheelchair accessible bathrooms in the building? ',
      popupLabel: 'Number Of Wheelchair Accessible Bathrooms',
      type: Question.TYPE_INTEGER,
      type2: {
        typeInteger: {
          minValue: 1,
          maxValue: Number.MAX_VALUE,
          scoring: [],
        }
      },
      maximumScore: 0,

      spreadsheetRow: 107,
      dependant: {
        questionKey: 'Bk2e5', // Is there a wheelchair accessible bathroom on the premises for employees?
      },
    },
    {
      key: 'Bk2fN',
      label: 'Do the accessible bathroom doors have braille, raised lettering, or appropriate symbols to identify the bathroom type?',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/24.Bk2fN.png'],
      helpText: 'Braille is a form of written language for blind people, in which characters are represented by patterns of raised dots that are felt with the fingertips. Raised (tactile) male/female/unisex/accessibility symbols should be included in the  accessible bathroom\'s signage.  ',
      popupLabel: 'Tactile Signage At Entrance',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 2,
            onFalse: 0,
          }
        }
      },
      maximumScore: 2,

      spreadsheetRow: 108,
      dependant: {
        questionKey: 'Bk2e5', // Is there a wheelchair accessible bathroom on the premises for employees?
        isEssential: true,
      },
    },
    {
      key: 'Bk2fX',
      label: 'Are there two doors in a series into any accessible bathroom where the wheelchair accessible toilet is located?',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/11.Bk2fX.jpg'],
      helpText: 'Entrances through two doors in a series are characterised by two consecutive doors that have a clear space in between them, often referred to as \'lobby\' or \'vestibule\'. ',
      popupLabel: 'Entrance Through Two Doors In A Series ',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 0,
            onFalse: 0,
          }
        }
      },
      maximumScore: 0,

      spreadsheetRow: 109,
      dependant: {
        questionKey: 'Bk2e5', // Is there a wheelchair accessible bathroom on the premises for employees?
      },
    },
    {
      key: 'Bk2fr',
      label: 'What is the distance between the two doors in the series? (eg. The passage between the two doors). (In cm)',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/12.Bk2fr.jpg'],
      helpText: 'Where there are two doors in a series into a bathroom, the space between the doors should be of 150cm. This allows wheelchair users to pass through one door and let it close behind them before reaching to open the second door. Please note that the 150cm of clear lobby space should be observed even when the doors are open.  ',
      popupLabel: 'Distance Between Two Doors In A Series ',
      type: Question.TYPE_CM_MEASUREMENT,
      type2: {
        typeCmMeasurement: {
          minValue: 60,
          maxValue: 10 * 1000,
          scoring: [
            {
              greaterThanOrEqualTo: 150,
              score: 3
            },
            {
              greaterThanOrEqualTo: 120,
              lessThan: 150,
              score: 2
            },
          ]
        }
      },
      maximumScore: 3,

      spreadsheetRow: 110,
      dependant: {
        questionKey: 'Bk2fX', // Are there two doors in a series into any accessible bathroom where the wheelchair accessible toilet is located?
        // isEssential: true,
      }
    },
    {
      key: 'Bk2f1', // In the most wheelchair accessible bathroom, what type of door handles are on the bathroom doors?
      label: 'In the most wheelchair accessible bathroom, what type of door handles are on the bathroom doors?',
      helpImage: null,
      helpImages: ['facilities-help-images.2022-08-05/Bk2f1.svg'],
      helpText: 'The most accessible door handles allow users to operate doors using only one hand in a closed fist. They don’t require tight grasping, pinching, or wrist twisting. Automatic door opening mechanisms and lever handles accommodate the needs of a wide range of people.',
      popupLabel: 'Employee accessible bathroom door handles',
      type: Question.TYPE_ENUM,
      type2: {
        typeEnum: {
          options: [
            FacilityEnumeratedConstants.TOILET_DOOR_TYPE.leverHandles,
            FacilityEnumeratedConstants.TOILET_DOOR_TYPE.automaticDoor,
            FacilityEnumeratedConstants.TOILET_DOOR_TYPE.pushButtonOpener,
            FacilityEnumeratedConstants.TOILET_DOOR_TYPE.roundHandles,
            FacilityEnumeratedConstants.TOILET_DOOR_TYPE.push,
          ],
          scoring: [
            {
              codeAsNumber: FacilityEnumeratedConstants.TOILET_DOOR_TYPE.leverHandles.codeAsNumber,
              score: 2,
            },
            {
              codeAsNumber: FacilityEnumeratedConstants.TOILET_DOOR_TYPE.automaticDoor.codeAsNumber,
              score: 3,
            },
            {
              codeAsNumber: FacilityEnumeratedConstants.TOILET_DOOR_TYPE.pushButtonOpener.codeAsNumber,
              score: 3,
            },
            {
              codeAsNumber: FacilityEnumeratedConstants.TOILET_DOOR_TYPE.roundHandles.codeAsNumber,
              score: 0,
            },
            {
              codeAsNumber: FacilityEnumeratedConstants.TOILET_DOOR_TYPE.push.codeAsNumber,
              score: 1,
            },
          ],
        }
      },
      maximumScore: 3,

      spreadsheetRow: 111,
      dependant: {
        questionKey: 'Bk2e5', // Is there a wheelchair accessible bathroom on the premises for employees?
        isEssential: true,
      }
    },
    {
      key: 'D1sjR', // In the most wheelchair accessible bathroom, what type of door locks are on the bathroom door?
      label: 'In the most wheelchair accessible bathroom, what type of door locks are on the bathroom door?',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/135.D1sjR.svg'],
      helpText: 'Can the lock on the bathroom door be operated with one hand in a closed fist, ie does not require tight grasping, pinching or twisting of the wrist? ',
      popupLabel: 'Employee accessible bathroom door lock',
      type: Question.TYPE_ENUM,
      type2: {
        typeEnum: {
          options: [
            FacilityEnumeratedConstants.BATHROOM_DOOR_LOCK_TYPE.leverHandle,
            FacilityEnumeratedConstants.BATHROOM_DOOR_LOCK_TYPE.slideBolt,
            FacilityEnumeratedConstants.BATHROOM_DOOR_LOCK_TYPE.thumbTurn,
            FacilityEnumeratedConstants.BATHROOM_DOOR_LOCK_TYPE.other,
          ],
          scoring: [
            {
              codeAsNumber: FacilityEnumeratedConstants.BATHROOM_DOOR_LOCK_TYPE.leverHandle.codeAsNumber,
              score: 1,
            },
            {
              codeAsNumber: FacilityEnumeratedConstants.BATHROOM_DOOR_LOCK_TYPE.slideBolt.codeAsNumber,
              score: 0,
            },
            {
              codeAsNumber: FacilityEnumeratedConstants.BATHROOM_DOOR_LOCK_TYPE.thumbTurn.codeAsNumber,
              score: 0,
            },
            {
              codeAsNumber: FacilityEnumeratedConstants.BATHROOM_DOOR_LOCK_TYPE.other.codeAsNumber,
              score: 0,
            },
          ]
        }
      },
      maximumScore: 1,

      spreadsheetRow: -1,
      dependant: {
        questionKey: 'Bk2e5', // Is there a wheelchair accessible bathroom on the premises for employees?
        isEssential: true,
      }
    },
    {
      key: 'Bk2f_',
      label: 'In the most wheelchair accessible bathroom, what is the width of the door?   (In cm)',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/19.Bk2f_.jpg'],
      helpText: 'In centimetres, please measure the width of the bathroom door in a fully open position, from inside the door frame as shown.',
      popupLabel: 'Door Width Measurement',
      type: Question.TYPE_CM_MEASUREMENT,
      type2: {
        typeCmMeasurement: {
          minValue: 60,
          maxValue: 2 * 1000,
          scoring: [
            {
              greaterThanOrEqualTo: 91,
              score: 3
            },
            {
              greaterThanOrEqualTo: 81,
              lessThan: 91,
              score: 2
            },
          ]
        }
      },
      maximumScore: 3,

      spreadsheetRow: 112,
      dependant: {
        questionKey: 'Bk2e5', // Is there a wheelchair accessible bathroom on the premises for employees?
        isEssential: true,
      }
    },
    {
      key: 'Bk2gJ',
      label: 'In the most wheelchair accessible bathroom, can you see yourself in the mirror from a seated position?',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/32.Bk2gJ.png'],
      helpText: 'The wheelchair accessible bathroom should include at least one mirror set at a lower height to allow wheelchair users, children, and people of short stature to see their reflection.  Select N/A if there is not a mirror in the wheelchair accessible bathroom.',
      popupLabel: 'Mirror At Accessible Height ',
      type: Question.TYPE_TERNARY,
      type2: {
        typeTernary: {
          scoring: {
            onNotApplicable: 1,
            onTrue: 1,
          }
        }
      },
      maximumScore: 1,

      spreadsheetRow: 113,
      dependant: {
        questionKey: 'Bk2e5', // Is there a wheelchair accessible bathroom on the premises for employees?
        isEssential: true,

      }
    },
    {
      key: 'Bk2gT',
      label: 'In the most wheelchair accessible bathroom, what is the toilet height from the floor to the top of the toilet seat? (In cm)',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/33.Bk2gt.png'],
      helpText: 'In centimetres, please measure the toilet seat height from the floor to the top of the toilet seat.\n',
      popupLabel: 'Toilet Height ',
      type: Question.TYPE_CM_MEASUREMENT,
      type2: {
        typeCmMeasurement: {
          minValue: 45,
          maxValue: 90,
          scoring: [
            {
              greaterThanOrEqualTo: 46,
              lessThan: 52,
              score: 3
            },
          ]
        }
      },
      maximumScore: 3,

      spreadsheetRow: 114,
      dependant: {
        questionKey: 'Bk2e5', // Is there a wheelchair accessible bathroom on the premises for employees?
        isEssential: true,
      }
    },


    {
      key: 'ajx1I', // In the most wheelchair accessible bathroom, what type of flush controls are provided?
      label: 'In the most wheelchair accessible bathroom, what type of flush controls are provided? ',
      helpImage: null,
      helpImages: ['facilities-help-images.2022-08-05/ajx1I.png'],
      helpText: 'Toilet flush mechanisms need to be easy to reach and easy to operate for someone with limited hand function.',
      popupLabel: '',
      type: Question.TYPE_ENUM,
      type2: {
        typeEnum: {
          options: [
            FacilityEnumeratedConstants.TOILET_FLUSH_HANDLE_TYPE.flush,
            FacilityEnumeratedConstants.TOILET_FLUSH_HANDLE_TYPE.pad,
            FacilityEnumeratedConstants.TOILET_FLUSH_HANDLE_TYPE.cisternButton,
            FacilityEnumeratedConstants.TOILET_FLUSH_HANDLE_TYPE.sensor
          ],
          scoring: [
            {
              codeAsNumber: FacilityEnumeratedConstants.TOILET_FLUSH_HANDLE_TYPE.flush.codeAsNumber,
              score: 1
            },
            {
              codeAsNumber: FacilityEnumeratedConstants.TOILET_FLUSH_HANDLE_TYPE.pad.codeAsNumber,
              score: 1
            },
            {
              codeAsNumber: FacilityEnumeratedConstants.TOILET_FLUSH_HANDLE_TYPE.cisternButton.codeAsNumber,
              score: 0
            },
            {
              codeAsNumber: FacilityEnumeratedConstants.TOILET_FLUSH_HANDLE_TYPE.sensor.codeAsNumber,
              score: 2
            },
          ],
        }
      },
      maximumScore: 2,

      spreadsheetRow: 3,
      enumOptions: [
        FacilityEnumeratedConstants.TOILET_FLUSH_HANDLE_TYPE.flush,
        FacilityEnumeratedConstants.TOILET_FLUSH_HANDLE_TYPE.pad,
        FacilityEnumeratedConstants.TOILET_FLUSH_HANDLE_TYPE.cisternButton,
        FacilityEnumeratedConstants.TOILET_FLUSH_HANDLE_TYPE.sensor
      ],
      dependant: {
        questionKey: 'Bk2e5', // Is there a wheelchair accessible bathroom on the premises for employees?
        isEssential: true,
      }
    },


    {
      key: 'Bk2gn',
      label: 'In the most wheelchair accessible bathroom, what is the widest clear floor space beside the accessible toilet? ',
      helpImage: null,
      helpImages: ['facilities-help-images.2022-08-05/Bk2gn.png'],
      helpText: 'In centimetres, please measure the largest (widest) space at one side of the toilet so a wheelchair can fit beside the toilet for lateral transfer.',
      popupLabel: 'Widest Clear Floor Space Beside Toilet',
      type: Question.TYPE_CM_MEASUREMENT,
      type2: {
        typeCmMeasurement: {
          minValue: 60,
          maxValue: 10 * 1000,
          scoring: [
            {
              greaterThanOrEqualTo: 90,
              lessThan: 91,
              score: 2
            },
            {
              greaterThanOrEqualTo: 91,
              score: 3
            },
          ]
        }
      },
      maximumScore: 3,

      spreadsheetRow: 115,
      dependant: {
        questionKey: 'Bk2e5', // Is there a wheelchair accessible bathroom on the premises for employees?
        isEssential: true,
      }
    },


    {
      key: 'ajzYg',
      label: 'In the most accessible bathroom, is the toilet flush handle located beside a wide clear floor space?',
      helpImage: null,
      helpImages: ['facilities-help-images.2022-08-05/ajzYg.png'],
      helpText: 'Hand operated flush controls need to be located on the side of the cistern that has open space, allowing room for a wheelchair user to access the handle. The flush handle can be difficult to reach if it is positioned on the side closest or adjacent to the wall.',
      popupLabel: 'Clear Space Beside Toilet Flush Handle',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 1,
            onFalse: 0,
          }
        }
      },
      maximumScore: 1,

      spreadsheetRow: 7,
      dependant: {
        questionKey: 'ajx1I', // In the most wheelchair accessible bathroom, what type of flush controls are provided?
        truthyEnumCodes: [
          FacilityEnumeratedConstants.TOILET_FLUSH_HANDLE_TYPE.flush.codeAsNumber
        ],
        // isEssential: true,
      },
    },


    {
      key: 'Bk2gx',
      label: 'In the most wheelchair accessible bathroom, is there a grab bar beside the accessible toilet?',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/35.Bk2gx.png'],
      helpText: 'Are there grab rails beside the toilet positioned to assist balance?',
      popupLabel: 'Grab Bar Beside Toilet',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 2,
            onFalse: 0,
          }
        }
      },
      maximumScore: 2,

      spreadsheetRow: 116,
      dependant: {
        questionKey: 'Bk2e5', // Is there a wheelchair accessible bathroom on the premises for employees?
        isEssential: true,
      }
    },
    {
      key: 'eauye', // Is there a sink at a lower height in the accessible bathroom?
      label: 'Is there a sink at a lower height in the accessible bathroom?',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/28.CjqNt.png'],
      helpText: 'The accessible bathroom should include at least one sink/washbasin positioned no higher than 84cm from the floor level. Providing a lower sink allows wheelchair users, children, and people of short stature to wash their hands comfortably. \n',
      popupLabel: 'Sink At Accessible Height',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 1,
            onFalse: 0,
          }
        }
      },
      maximumScore: 1,

      spreadsheetRow: -1,
      dependant: {
        questionKey: 'Bk2e5', // Is there a wheelchair accessible bathroom on the premises for employees?
        isEssential: true,
      }
    },
    {
      key: 'eau1c', // What is the height of the lower sink in the accessible bathrooms?
      label: 'What is the height of the lower sink in the accessible bathrooms?',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/36.CjqVr.png'],
      helpText: 'In centimetres, please measure the height of the lowest sink from the floor to the top of the washbasin.',
      popupLabel: 'Height Of Lowest Sink',
      type: Question.TYPE_CM_MEASUREMENT,
      type2: {
        typeCmMeasurement: {
          minValue: 45,
          maxValue: 2000,
          scoring: [
            {
              greaterThanOrEqualTo: 86,
              score: 0
            },
            {
              greaterThanOrEqualTo: 75,
              lessThan: 86,
              score: 1
            },
          ]
        }
      },
      maximumScore: 1,

      spreadsheetRow: -1,
      dependant: {
        questionKey: 'eauye', // Is there a sink at a lower height in the accessible bathroom?
        isEssential: true,
      },
    },

    {
      key: 'Bk2g7',
      label: 'In the most wheelchair accessible bathroom, is there a clear space under the sink to enable a wheelchair user to fit under?',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/36.Bk2g7.png'],
      helpText: 'The sink in the wheelchair accessible bathroom should  have a clear space underneath it to allow wheelchair users to use the sink comfortably. 70cm of knee clearance space underneath the sink is recommended. ',
      popupLabel: 'Clear Space Under The Sink ',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 2,
            onFalse: 0,
          }
        }
      },
      maximumScore: 2,

      spreadsheetRow: 117,
      dependant: {
        questionKey: 'Bk2e5', // Is there a wheelchair accessible bathroom on the premises for employees?
        isEssential: true,
      }
    },
    {
      key: 'Bk2hF',
      label: 'In the most wheelchair accessible bathroom, what type of handle is on the sink? ',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/27.Bk2hF.png'],
      helpText: 'Sink tap handles should have long levers to allow people with poor hand dexterity to easily control the water flow. Taps operated by automatic sensors or press buttons are also suitable.  Operating swirl/round head taps might be challenging for some people. ',
      popupLabel: 'Sink handle type',
      type: Question.TYPE_ENUM,
      type2: {
        typeEnum: {
          options: [
            FacilityEnumeratedConstants.TOILET_SINK_HANDLE_TYPE.lever,
            FacilityEnumeratedConstants.TOILET_SINK_HANDLE_TYPE.round,
            FacilityEnumeratedConstants.TOILET_SINK_HANDLE_TYPE.press,
            FacilityEnumeratedConstants.TOILET_SINK_HANDLE_TYPE.sensor,
          ],
          scoring: [
            {
              codeAsNumber: FacilityEnumeratedConstants.TOILET_SINK_HANDLE_TYPE.lever.codeAsNumber,
              score: 2,
            },
            {
              codeAsNumber: FacilityEnumeratedConstants.TOILET_SINK_HANDLE_TYPE.round.codeAsNumber,
              score: 0,
            },
            {
              codeAsNumber: FacilityEnumeratedConstants.TOILET_SINK_HANDLE_TYPE.press.codeAsNumber,
              score: 2,
            },
            {
              codeAsNumber: FacilityEnumeratedConstants.TOILET_SINK_HANDLE_TYPE.sensor.codeAsNumber,
              score: 2,
            },
          ],
        }
      },
      maximumScore: 2,

      spreadsheetRow: 118,
      dependant: {
        questionKey: 'Bk2e5', // Is there a wheelchair accessible bathroom on the premises for employees?
        isEssential: true,
      },
    },
    {
      key: 'Bk2hP',
      label: 'In the most wheelchair accessible bathroom, is there an alarm cord or button for emergency use?  ',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/38.Bk2hP.svg'],
      helpText: 'The wheelchair accessible bathroom should include a red pull cord or alarm button to allow people to summon assistance, if needed. ',
      popupLabel: 'Emergency Alarm Cord',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 2,
            onFalse: 0,
          }
        }
      },
      maximumScore: 2,

      spreadsheetRow: 119,
      dependant: {
        questionKey: 'Bk2e5', // Is there a wheelchair accessible bathroom on the premises for employees?
        isEssential: true,
      },
    },
    {
      key: 'Bk2hZ',
      label: 'Is there a roll-in shower on the premises?',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/78.Bk2hZ.svg'],
      helpText: 'Does the accessible bathroom have a level floor roll-in shower/wet room?',
      popupLabel: 'Roll-In Shower',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 3,
            onFalse: 0,
          }
        }
      },
      maximumScore: 3,

      spreadsheetRow: 120,
      dependant: {
        questionKey: 'Bk2e5', // Is there a wheelchair accessible bathroom on the premises for employees?
        isEssential: true,
      },
    },
    {
      key: 'Bk2hj',
      label: 'Please take 2 photos of the most  accessible bathroom/restroom from different angles (in landscape mode)',
      helpImage: null,
      helpText: '',
      popupLabel: '',
      type: Question.TYPE_PHOTO,
      maximumScore: 0,

      spreadsheetRow: 121,
      dependant: {
        questionKey: 'Bk2e5' // Is there a wheelchair accessible bathroom on the premises for employees?
      },
    },
    {
      key: 'Cjlgx', // What type of handles are on the general bathroom entrance doors?
      label: 'What type of handles are on the general bathroom entrance doors? ',
      helpImage: null,
      helpImages: ['facilities-help-images.2022-08-05/Cjlgx.svg'],
      helpText: 'The most accessible door handles allow users to operate doors using only one hand in a closed fist. They don’t require tight grasping, pinching, or wrist twisting. Automatic door opening mechanisms and lever handles accommodate the needs of a wide range of people.',
      popupLabel: 'Visitor bathroom door handle type',
      type: Question.TYPE_ENUM,
      type2: {
        typeEnum: {
          options: [
            FacilityEnumeratedConstants.TOILET_DOOR_TYPE.automaticDoor,
            FacilityEnumeratedConstants.TOILET_DOOR_TYPE.pushButtonOpener,
            FacilityEnumeratedConstants.TOILET_DOOR_TYPE.leverHandles,
            FacilityEnumeratedConstants.TOILET_DOOR_TYPE.roundHandles,
            FacilityEnumeratedConstants.TOILET_DOOR_TYPE.push,
          ],
          scoring: [
            {
              codeAsNumber: FacilityEnumeratedConstants.TOILET_DOOR_TYPE.automaticDoor.codeAsNumber,
              score: 3,
            },
            {
              codeAsNumber: FacilityEnumeratedConstants.TOILET_DOOR_TYPE.pushButtonOpener.codeAsNumber,
              score: 3,
            },
            {
              codeAsNumber: FacilityEnumeratedConstants.TOILET_DOOR_TYPE.leverHandles.codeAsNumber,
              score: 2,
            },
            {
              codeAsNumber: FacilityEnumeratedConstants.TOILET_DOOR_TYPE.roundHandles.codeAsNumber,
              score: 0,
            },
            {
              codeAsNumber: FacilityEnumeratedConstants.TOILET_DOOR_TYPE.push.codeAsNumber,
              score: 1,
            },
          ]
        }
      },
      maximumScore: 3,

      spreadsheetRow: -1,
    },
    {
      key: 'D1sTp', // What type of door locks are on the general bathroom doors?
      label: 'What type of door locks are on the general bathroom doors?',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/135.D1sTp.svg'],
      helpText: 'Can the lock on the bathroom door be operated with one hand in a closed fist, ie does not require tight grasping, pinching or twisting of the wrist? ',
      popupLabel: 'Visitors bathroom type of lock',
      type: Question.TYPE_ENUM,
      type2: {
        typeEnum: {
          options: [
            FacilityEnumeratedConstants.BATHROOM_DOOR_LOCK_TYPE.leverHandle,
            FacilityEnumeratedConstants.BATHROOM_DOOR_LOCK_TYPE.slideBolt,
            FacilityEnumeratedConstants.BATHROOM_DOOR_LOCK_TYPE.thumbTurn,
            FacilityEnumeratedConstants.BATHROOM_DOOR_LOCK_TYPE.other,
          ],
          scoring: [
            {
              codeAsNumber: FacilityEnumeratedConstants.BATHROOM_DOOR_LOCK_TYPE.leverHandle.codeAsNumber,
              score: 1,
            },
            {
              codeAsNumber: FacilityEnumeratedConstants.BATHROOM_DOOR_LOCK_TYPE.slideBolt.codeAsNumber,
              score: 0,
            },
            {
              codeAsNumber: FacilityEnumeratedConstants.BATHROOM_DOOR_LOCK_TYPE.thumbTurn.codeAsNumber,
              score: 0,
            },
            {
              codeAsNumber: FacilityEnumeratedConstants.BATHROOM_DOOR_LOCK_TYPE.other.codeAsNumber,
              score: 0,
            },
          ]
        }
      },
      maximumScore: 1,

      spreadsheetRow: -1,
    },

    {
      key: 'Cjp6z',
      label: 'What is the width of the general bathroom doors? (In cm)',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/26.Cjp6z.jpg'],
      helpText: 'In centimetres, please measure the width of the bathroom door in a fully open position, from inside the door frame as shown.',
      popupLabel: 'Door Width Measurement',
      type: Question.TYPE_CM_MEASUREMENT,
      type2: {
        typeCmMeasurement: {
          minValue: 45,
          maxValue: 2000,
          scoring: [
            {
              greaterThanOrEqualTo: 91,
              score: 3
            },
            {
              greaterThanOrEqualTo: 81,
              lessThan: 91,
              score: 2
            },
          ]
        }
      },
      maximumScore: 3,

      spreadsheetRow: -1,
    },
    {
      key: 'Cjp_z',
      label: 'Can you see yourself in the mirror from a seated position in the general bathrooms?',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/26.Cjp_z.png'],
      helpText: 'The general bathroom should include at least one mirror set at a lower height to allow wheelchair users, children, and people of short stature to see their reflection.  Select N/A if there is no mirror in the general bathroom/restroom.',
      popupLabel: 'Mirror At Accessible Height  ',
      type: Question.TYPE_TERNARY,
      type2: {
        typeTernary: {
          scoring: {
            onNotApplicable: 1,
            onTrue: 1,
          }
        }
      },
      maximumScore: 1,

      spreadsheetRow: -1,
    },
    {
      key: 'Cjwul',
      label: 'What type of tap handles are on the sink in the general bathrooms?',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/27.Cjwul.png'],
      helpText: 'Sink tap handles should have long levers to allow people with poor hand dexterity to easily control the water flow. Taps operated by automatic sensors or press buttons are also suitable.  Operating swirl/round head taps might be challenging for some people. ',
      popupLabel: 'Visitor bathroom sink handle type',
      type: Question.TYPE_ENUM,
      type2: {
        typeEnum: {
          options: [
            FacilityEnumeratedConstants.TOILET_SINK_HANDLE_TYPE.lever,
            FacilityEnumeratedConstants.TOILET_SINK_HANDLE_TYPE.round,
            FacilityEnumeratedConstants.TOILET_SINK_HANDLE_TYPE.press,
            FacilityEnumeratedConstants.TOILET_SINK_HANDLE_TYPE.sensor,
          ],
          scoring: [
            {
              codeAsNumber: FacilityEnumeratedConstants.TOILET_SINK_HANDLE_TYPE.lever.codeAsNumber,
              score: 2,
            },
            {
              codeAsNumber: FacilityEnumeratedConstants.TOILET_SINK_HANDLE_TYPE.round.codeAsNumber,
              score: 0,
            },
            {
              codeAsNumber: FacilityEnumeratedConstants.TOILET_SINK_HANDLE_TYPE.press.codeAsNumber,
              score: 2,
            },
            {
              codeAsNumber: FacilityEnumeratedConstants.TOILET_SINK_HANDLE_TYPE.sensor.codeAsNumber,
              score: 2,
            },
          ]
        }
      },
      maximumScore: 2,

      spreadsheetRow: -1,
    },
    {
      key: 'CjqNt',
      label: 'Is there a sink at a lower height in the general bathrooms?',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/28.CjqNt.png'],
      helpText: 'The general bathroom should include at least one sink/washbasin positioned at a lower height to allow wheelchair users, children, and people of short stature to wash their hands comfortably. ',
      popupLabel: 'Sink At Accessible Height',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 1,
            onFalse: 0,
          }
        }
      },
      maximumScore: 1,
      spreadsheetRow: -1,
    },
    {
      key: 'CjqVr',
      label: 'What is the height of the lower sink in the general bathrooms? ',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/36.CjqVr.png'],
      helpText: 'In centimetres, please measure the height of the lowest sink from the floor to the top of the washbasin.',
      popupLabel: 'Height Of Lowest Sink',
      type: Question.TYPE_CM_MEASUREMENT,
      type2: {
        typeCmMeasurement: {
          minValue: 45,
          maxValue: 2000,
          scoring: [
            {
              greaterThanOrEqualTo: 86,
              score: 0
            },
            {
              greaterThanOrEqualTo: 75,
              lessThan: 86,
              score: 1
            },
          ]
        }
      },
      maximumScore: 1,

      spreadsheetRow: -1,
      dependant: {
        questionKey: 'CjqNt', // Is there a sink at a lower height in the accessible bathroom?
        // isEssential: true,
      },
    },
    {
      key: 'QZ_0J',
      label: 'Do you have any other comments or information about this area?',
      helpImage: null,
      helpText: '',
      popupLabel: '',
      type: Question.TYPE_TEXT,
      maximumScore: 0,

      spreadsheetRow: -1,
    },


  ];
  public static readonly SAFETY_PROCEDURES_QUESTIONS: IQuestion[] = [

    //
    //
    // SAFETY AND EVACUATION (row: 122)
    //
    //

    {
      key: 'Bk3dP',
      label: 'Does the fire alarm system have visible flashing lights?',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/80.Bk3dP.svg'],
      helpText: 'Alarms with visual flashing lights are important as they warn people with a hearing impairments, who might be unable to detect audible queues. ',
      popupLabel: 'Visible Flashing Alarm  ',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 2,
            onFalse: 0,
          }
        }
      },
      maximumScore: 2,

      spreadsheetRow: 123,
    },
    {
      key: 'Bk3dZ',
      label: 'Is there an emergency evacuation procedure available for people with accessibility needs?',
      helpImage: null,
      helpImages: ['facilities-help-images.2022-08-05/Bk3dZ.png'],
      helpText: 'Do you have an emergency evacuation procedure specifically for those with accessibility needs?',
      popupLabel: 'Emergency Evacuation Procedure',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 3,
            onFalse: 0,
          }
        }
      },
      maximumScore: 3,

      spreadsheetRow: 124,
    },
    {
      key: 'Bk3dj',
      label: 'Is emergency evacuation information available in both visual and audible formats?',
      helpImage: null,
      helpText: 'An emergency evacuation plan shows the immediate escape routes in the case of danger. It should be available in all formats, including visual and auditory formats - written, braille, digital, audio, large print.',
      popupLabel: 'Visual And Audible Evacuation Information',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 3,
            onFalse: 0,
          }
        }
      },
      maximumScore: 3,

      spreadsheetRow: 125,
    },
    {
      key: 'Bk3dt',
      label: 'Is there an emergency evacuation chair in the building?\n',
      helpImage: null,
      helpImages: ['facilities-help-images/Consolidated_Facilities_Images/81.Bk3dt.svg'],
      helpText: 'Evacuation chairs provide mobility impaired people with a safe and smooth descent of stairways to evacuate a building in the event of an emergency, such as a fire.',
      popupLabel: 'Emergency Evacuation Chair',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 3,
            onFalse: 0,
          }
        }
      },
      maximumScore: 3,

      spreadsheetRow: 126,
    },
    {
      key: 'XQATW', // In the case of fire or an emergency, are areas of refuge provided in your building?
      label: 'In the case of fire or an emergency, are areas of refuge provided in your building?',
      helpImage: null,
      helpImages: ['facilities-help-images.2022-08-05/XQATW.png'],
      helpText: 'Areas of refuge are relatively safe areas where people can remain until help arrives.   N/A means your building is fitted with a sprinkler system and does not require a designated refuge area.',
      popupLabel: 'Areas Of Refuge',
      type: Question.TYPE_TERNARY,
      type2: {
        typeTernary: {
          scoring: {
            onNotApplicable: 3,
            onTrue: 3,
          }
        }
      },
      maximumScore: 3,

      spreadsheetRow: null,
    },



    {
      key: 'TgYWG',
      label: 'Are all emergency exits clearly signposted?',
      helpImage: null,
      helpImages: ['facilities-help-images.2022-08-05/TgYWG.svg'],
      helpText: 'Emergency exit signage helps safe egress and evacuation for all.',
      popupLabel: 'Emergency Exits Clearly Signposted',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 3,
            onFalse: 0,
          }
        }
      },
      maximumScore: 3,

      spreadsheetRow: -1,
    },




    {
      key: 'Bk3d3',
      label: 'Do staff receive disability awareness training?\n',
      helpImage: null,
      helpImages: ['facilities-help-images.2022-08-05/Bk3d3.svg'],
      helpText: 'Have your staff received any disability awareness training to help them better understand the key issues regarding disabilities?',
      popupLabel: 'Staff disability awareness training',
      type: Question.TYPE_BOOLEAN,
      type2: {
        typeBoolean: {
          scoring: {
            onTrue: 3,
            onFalse: 0,
          }
        }
      },
      maximumScore: 3,

      spreadsheetRow: 127,
    },
    {
      key: 'Bk3eB', // Does the building have an accessibility compliance certification, inclusion or universal design standards, building code accreditation?
      label: 'Does the building have an accessibility compliance certification, inclusion or universal design standards, building code accreditation?  ',
      helpImage: null,
      helpImages: ['facilities-help-images.2022-08-05/Bk3eB.png'],
      helpText: 'Building codes for accessibility are an accreditation for the  accessibility of the built environment and usability of a building based on local accessibility standards. There are many forms of accessibility accreditations globally. Does your building have any accessibility accreditations/certifications?',
      popupLabel: 'Accessibility Compliance Certification',
      type: Question.TYPE_ENUM,
      type2: {
        typeEnum: {
          options: [
            FacilityEnumeratedConstants.ACCESSIBILITY_CERTIFICATION.ada,
            FacilityEnumeratedConstants.ACCESSIBILITY_CERTIFICATION.dac,
            FacilityEnumeratedConstants.ACCESSIBILITY_CERTIFICATION.partM,
            FacilityEnumeratedConstants.ACCESSIBILITY_CERTIFICATION.bac,
            FacilityEnumeratedConstants.ACCESSIBILITY_CERTIFICATION.nbc,
            FacilityEnumeratedConstants.ACCESSIBILITY_CERTIFICATION.bbr,
            FacilityEnumeratedConstants.ACCESSIBILITY_CERTIFICATION.other,
            FacilityEnumeratedConstants.ACCESSIBILITY_CERTIFICATION.none,
          ],
          scoring: [
            {
              codeAsNumber: FacilityEnumeratedConstants.ACCESSIBILITY_CERTIFICATION.ada.codeAsNumber,
              score: 3,
            },
            {
              codeAsNumber: FacilityEnumeratedConstants.ACCESSIBILITY_CERTIFICATION.dac.codeAsNumber,
              score: 3,
            },
            {
              codeAsNumber: FacilityEnumeratedConstants.ACCESSIBILITY_CERTIFICATION.partM.codeAsNumber,
              score: 3,
            },
            {
              codeAsNumber: FacilityEnumeratedConstants.ACCESSIBILITY_CERTIFICATION.bac.codeAsNumber,
              score: 3,
            },
            {
              codeAsNumber: FacilityEnumeratedConstants.ACCESSIBILITY_CERTIFICATION.nbc.codeAsNumber,
              score: 3,
            },
            {
              codeAsNumber: FacilityEnumeratedConstants.ACCESSIBILITY_CERTIFICATION.bbr.codeAsNumber,
              score: 3,
            },
            {
              codeAsNumber: FacilityEnumeratedConstants.ACCESSIBILITY_CERTIFICATION.other.codeAsNumber,
              score: 3,
            },
            {
              codeAsNumber: FacilityEnumeratedConstants.ACCESSIBILITY_CERTIFICATION.none.codeAsNumber,
              score: 0,
            },
          ],
        }
      },
      maximumScore: 3,

      spreadsheetRow: 128,
      enumOptions: [
        FacilityEnumeratedConstants.ACCESSIBILITY_CERTIFICATION.ada,
        FacilityEnumeratedConstants.ACCESSIBILITY_CERTIFICATION.dac,
        FacilityEnumeratedConstants.ACCESSIBILITY_CERTIFICATION.partM,
        FacilityEnumeratedConstants.ACCESSIBILITY_CERTIFICATION.bac,
        FacilityEnumeratedConstants.ACCESSIBILITY_CERTIFICATION.nbc,
        FacilityEnumeratedConstants.ACCESSIBILITY_CERTIFICATION.bbr,
        FacilityEnumeratedConstants.ACCESSIBILITY_CERTIFICATION.other,
        FacilityEnumeratedConstants.ACCESSIBILITY_CERTIFICATION.none,
      ]
    },
    {
      key: 'DHYrn',
      label: 'What type of accessibility certification, inclusion or universal design standards accreditation does the building have ?',
      helpImage: null,
      helpText: 'Please provide more details on the type of accessibility certification your building has.',
      popupLabel: 'Accessibility Certification',
      type: Question.TYPE_TEXT,
      maximumScore: 2,

      spreadsheetRow: 158,
      dependant: {
        questionKey: 'Bk3eB',
        truthyEnumCodes: [
          FacilityEnumeratedConstants.ACCESSIBILITY_CERTIFICATION.other.codeAsNumber
        ]
      }
    },
    {
      key: 'QZ_1P',
      label: 'Do you have any other comments or information about this area?',
      helpImage: null,
      helpText: '',
      popupLabel: '',
      type: Question.TYPE_TEXT,
      maximumScore: 0,

      spreadsheetRow: -1,
    },

  ];

}
