// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

import {IProductEnvironment} from "./FacilitiesEvaluationEnvironment";
import {FacilityAccessibilityProduct} from "../product.facility/model/FacilityAccessibilityProduct";
import {EProductType} from "../javascript.lib.mojo-base/model/ProductType";
import {FacilityAccessibilityProduct2} from "../product.facility/model/FacilityAccessibilityProduct2";
import {FacilityAccessibilityProduct3} from "../product.facility/model/FacilityAccessibilityProduct3";
import {FacilityAccessibilityProduct4} from "../product.facility/model/FacilityAccessibilityProduct4";

export const environment: IProductEnvironment = {

  filename: 'environment.manufacturing-444745.ts',
  isDevelopment: false,
  isProduction: true,
  isTest: false,

  authenticationConfig: {

    emailLink: false,
    textMessage: true,
    usernamePassword: true
  },


  firebaseConfig: {
    apiKey: "AIzaSyCMmiv0gBtLOn-WoOwyKeo2Qnmvoob61Xs",
    appId: "1:243265671328:web:217b5615578adba49bf9c5",
    authDomain: "manufacturing-444745.firebaseapp.com",
    databaseURL: "https://manufacturing-444745-default-rtdb.europe-west1.firebasedatabase.app",
    messagingSenderId: "243265671328",
    projectId: "manufacturing-444745",
    storageBucket: "manufacturing-444745.appspot.com",
  },

  // curl -iLS https://us-central1-$_TARGET_ENV.cloudfunctions.net/public/functions/info
  cloudFunctionsUrl: 'https://us-central1-manufacturing-444745.cloudfunctions.net',

  // nocoDbConfig: {
  //   // httpServer: 'http://localhost:8080',
  //   // httpServer: 'http://spot.local:8080',
  //   httpServer: 'https://mobilitymojo-spot.com',
  //   user: 'test@mobilitymojo.com',
  //   password: 'test@mobilitymojo.com',
  //   authorization: 'Basic bW9iaWxpdHk6bW9qbw==',
  // },

  productConfig: {

    firebaseAnswersRoot: '/answers-x/manufacturing',
    // product:  ProductHelper.cloneProduct( new FacilityAccessibilityProduct(), true )
    // product:  FacilityAccessibilityProduct3.INSTANCE
    product:  FacilityAccessibilityProduct4.INSTANCE,
    productType: EProductType.manufacturing,
  },




};
